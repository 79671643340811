.pgadmindasboard-bookingdashboard-child {
    margin-top: -50px;
    padding: 30px 0px;
    margin-top: 50px;
}

.is_upcoming_selected_class {
    display: none;
}

.bookingdetils_card_left {
    display: flex;
    min-height: 50vh;
}

.search_and_week_days_parent {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 80px;
}

.no-bookings-message h5 {
    color: var(--pink);
    display: flex;
    align-items: center;
    justify-content: center;

}

.booking_history_exel_icon {
    width: 30px;
}

.week-year-month-btns-div {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 20px; */
}

.bookings-filter-active-btn {
    background-color: var(--click-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: 700;
}

.bookings-filter-inactive-btn {
    padding: 7px 30px;
    text-align: center;
    border: 2px solid var(--click-color);
    background: none;
    border-radius: 6px;
    color: var(--click-color);
    /* font-size: 16px; */
    letter-spacing: 0.7px;
    cursor: pointer;
}

.inactive-btn:hover {
    background-color: #e0e0e0;
}

@media only screen and (max-width: 560px) {
    .pgadmindasboard-bookingdashboard-child {
        margin-top: 30px;
    }

    .search_and_week_days_parent {
        flex-direction: column;
    }

    .bookings-filter-active-btn {
        background-color: var(--click-color);
        color: white;
        border: none;
        padding: 2px 10px;
        border-radius: 5px;
    }

    .bookings-filter-inactive-btn {
        padding: 0px 10px;
        text-align: center;
        border: 2px solid var(--click-color);
        background: none;
        border-radius: 6px;
        color: var(--click-color);
        font-weight: 700;
        /* font-size: 16px; */
        letter-spacing: 0.7px;
        cursor: pointer;
    }
}