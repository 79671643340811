/* nunito font family primary font family  */
@font-face {
  font-family: p_extralight;
  src: url(../public/assets/font/nunito/Nunito-ExtraLight.ttf);
}

@font-face {
  font-family: p_light;
  src: url(../public/assets/font/nunito/Nunito-Light.ttf);
}

@font-face {
  font-family: p_regular;
  src: url(../public/assets/font/nunito/Nunito-Regular.ttf);
}

@font-face {
  font-family: p_medium;
  src: url(../public/assets/font/nunito/Nunito-Medium.ttf);
}

@font-face {
  font-family: p_semibold;
  src: url(../public/assets/font/nunito/Nunito-SemiBold.ttf);
}

@font-face {
  font-family: p_bold;
  src: url(../public/assets/font/nunito/Nunito-Bold.ttf);
}

@font-face {
  font-family: p_extrabold;
  src: url(../public/assets/font/nunito/Nunito-ExtraBold.ttf);
}

.p_extralight {
  font-family: p_extralight;
}

.p_light {
  font-family: p_light;
}

.p_regular {
  font-family: p_regular;
}

.p_medium {
  font-family: p_medium;
}

.p_bold {
  font-family: p_bold;
}

.p_extrabold {
  font-family: extrabold;
}

/* nunito font family primary font family  */

/* nunito sans display font family secondary font family */
@font-face {
  font-family: s_regular;
  src: url(../public/assets/font/nunito_sans/NunitoSans_7pt-Regular.ttf);
}

@font-face {
  font-family: s_medium;
  src: url(../public/assets/font/nunito_sans/NunitoSans_7pt-Medium.ttf);
}

@font-face {
  font-family: s_semibold;
  src: url(../public/assets/font/nunito_sans/NunitoSans_7pt-SemiBold.ttf);
}

@font-face {
  font-family: s_bold;
  src: url(../public/assets/font/nunito_sans/NunitoSans_7pt-Bold.ttf);
}

@font-face {
  font-family: s_extrabold;
  src: url(../public/assets/font/nunito_sans/NunitoSans_7pt-ExtraBold.ttf);
}

.s_regular {
  font-family: s_regular;
}

.s_medium {
  font-family: s_medium;
}

.s_semibold {
  font-family: s_semibold;
  letter-spacing: 0.4px;
}

.s_bold {
  font-family: s_bold;
}

.s_extrabold {
  font-family: s_extrabold;
}

/* playfair display font family  */


/* variables */
/* :root {
  --white-color: #fff;
  --grey-color: #E5E4E2;
  --grey-dark: #6d6d6d;
  --black-color: #000;
  --click-color: #149aa2;
  --light-click-color: #aefaff;
  --dark-blue: #012860;
  --pink: #f05b83;
  --gold: #FEC84D;
  --light-gold: #FFF9C4;
  --light-blue: #AED6F1;
  --light-blue-bg: #f2faff;
  --rose: #EEDADC;
  --light-rose-bg: #fff8f9;
  --red-color: #E10032;
  --lightblue-color: #54cccb;
  --dark-green: #013120;
  --bg-color: #f4ffff5e;
  --text-color: #444;
  --light-green: #DBE8E1;
  --material-blue: #2196F3;
  --green: #C8E6C9;
  --heading-color: #3F5E98;
  --darkgrey-color: #666;
  --lightgrey-color: #aaa;
  --peach-color: #FFBF90;
  --blue-color: #1B4480;
  --green-color: 188, 236, 224;
  --yellow-color: #FEEBA0;
  --cream-color: #F4F2EB;
  --buttonfg-color: #F4F2EB;
  --buttonbg-color: #e95733;
  --lightblue-color: #54cccb;
} */

.light {
  --click-color: #149aa2;
  --white-color: #fff;
  --same-white-color: white;
  --icon-color: "#012860";
  --grey-light: #E5E4E2;
  --grey-dark: #6d6d6d;
  --black-color: #000;
  --same-black-color: #000;
  --dark-blue: #012860;
  --same-dark-blue: #012860;
  --gold: #FEC84D;
  --light-gold: #FFF9C4;
  --light-blue: #AED6F1;
  --light-blue-bg: #f2faff;
  --pink: #f05b83;
  --rose: #EEDADC;
  --light-rose-bg: #fff8f9;
  --red-color: #E10032;
  --lightblue-color: #54cccb;
  --dark-green: #013120;
  --bg-color: #f4ffff5e;
  --text-color: #444;
  --light-green: #DBE8E1;
  --material-blue: #2196F3;
  --green: #C8E6C9;
  --heading-color: #3F5E98;
  --darkgrey-light: #666;
  --peach-color: #FFBF90;
  --blue-color: #1B4480;
  --green-color: 188, 236, 224;
  --yellow-color: #FEEBA0;
  --cream-color: #F4F2EB;
  --buttonfg-color: #F4F2EB;
  --buttonbg-color: #e95733;
  --lightblue-color: #54cccb;
  --page-bg: #f5f5f5;
  --card-bg: #e9e9e9;
  --text-grey: grey;
  --text-light-grey: #888;
  --dark-light-white: white;
}

.dark {
  --click-color: #149aa2;
  --page-bg: #1F2937;
  --card-bg: #2f3a4b;
  --icon-color: "#012860";
  --text-grey: white;
  --dark-blue: white;
  --same-dark-blue: #012860;
  --text-light-grey: #9ca3a9;
  --white-color: #000;
  --same-white-color: white;
  --same-black-color: #000;
  --black-color: #fff;
  --light-blue-bg: #2c5066;
  --pink: #f05b83;
  --grey-dark: #FEC84D;
  --dark-light-white: white;
}

.App {
  background: var(--page-bg);
}

/* scroll css start  */
::-webkit-scrollbar {
  width: 14px;
  border-radius: 10px;
}

/* Track  */
::-webkit-scrollbar-track {
  background: var(--light-blue-bg);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--click-color);
}

/* scroll css start  */
/* base styles */

body {
  font-family: p_regular;
  overflow-y: scroll;
  margin: 0;
}

.d_mobile {
  display: none;
}

@media only screen and (max-width: 767px) {

  button.btn_fill {
    padding: 5px 10px !important;
    font-size: 14px;
  }

  .mobilecontainer {
    max-width: 100% !important;
    width: inherit !important;
  }

  .d_mobile {
    display: block;
  }

  .d_desktop {
    display: none;
  }

  .d_desktop.w_parent {
    display: block;
  }

  .col-md-6.add {
    padding: 0px !important;
  }

}

ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}

ul {
  list-style-type: none;
}


textarea {
  min-height: 100px;
}

.select {
  width: 100%;
  z-index: 10;
}

.menu {
  /* .css-t3ipsp-control .css-inmdiq5-menu { */
  background: red;
  z-index: 999;
}

.formerror {
  /* padding: 5px 10px; */
  /* background: #fdc5c5; */
  /* display: inline-block; */
  /* border-radius: 5px; */
  color: #e10032;
  /* border: 1px solid #e10032; */
}

.error {
  color: var(--red-color);
  background: pink;
  border: 1px solid var(--red-color);
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.search_bar input:focus {
  outline: none !important;
  border: none !important;
}

.large {
  width: 100%;
  border-radius: 0 0 0 0;
}

.small {
  width: 0px;
  display: none;
  border-radius: 0 0 0 0;
}

@media (max-width: 992px) {
  .large {
    width: 0%;
    display: none;
    border-radius: 0 0 0 0;
  }

  .small {
    width: 100%;
    display: block;
    border-radius: 0 0 0 0;
  }
}

/* popup  */
.pop-up-div {
  opacity: 0;
  pointer-events: none;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  transition: 1s;
}

.pop-up-div.open {
  opacity: 1;
  pointer-events: all;
}

.pop-up-div div {
  position: relative;
  background: var(--white-color);
  padding: 20px 10px;
  border-radius: 10px;
  max-width: 500px;
  /* width: 100%; */
  text-align: center;
}

.pop-up-div div p {
  font-size: 1.1rem;
  color: var(--blue-color);
}

.pop-up-div div p strong {
  color: var(--blue-color);
}

.addon-service {
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #BCECE0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 180px;
  /* width:120px; */
  /* padding-top: 4%; */
  /* padding-bottom: 6%; */
}

.addon-service div .material-symbols-outlined {
  /* padding: 20px 0 0 0; */
  font-size: 2.5rem;
  color: #4CD7D0;
}

.addon-service-h5 {
  /* margin-bottom: 6%; */
  font-size: 0.7rem;
  color: #222;
  font-weight: 600;
  /* padding-top: 5px; */
}

.dashboardCardBoxoffer {
  /* padding: 10px; */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  height: 9rem;
  width: 100%;
  transition: 1s;
  overflow: hidden;
}

@media (max-width: 850px) {
  .dashboardCardBoxoffer {
    height: auto;
  }
}

.dashboardCardBox.active {
  height: 19.5rem;
}



h4.title {
  color: var(--dark-blue);
  font-size: 24px;
  font-family: s_bold;
  margin-bottom: 16px;
  letter-spacing: 0.4px;
}

button.btn_fill {
  background: var(--click-color);
  border: 2px solid var(--click-color);
  border-radius: 6px;
  color: var(--same-white-color);
  padding: 5px 15px;
  font-family: 'p_bold';
  letter-spacing: 1px;
  text-transform: capitalize;
  cursor: pointer;
}

.btn_border {
  padding: 7px 30px;
  text-align: center;
  border: 2px solid var(--click-color);
  background: none;
  border-radius: 6px;
  color: var(--click-color);
  font-weight: 700;
  /* font-size: 16px; */
  letter-spacing: 0.7px;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.form_field input,
.form_field select,
.form_field textarea {
  width: 100%;
  border: 2px solid #03468738;
  border-radius: 12px;
  padding: 10px;
  font-size: 16px;
  padding-left: 42px;
}

.form_field {
  margin-bottom: 14px;
  position: relative;
}

.form_field input::placeholder {
  color: var(--black-color);
  opacity: 0.5;
}



.field_icon {
  position: absolute;
  top: 10px;
  left: 12px;
}

input:focus-visible,
select:focus-visible {
  outline: none;
  border: 1px solid var(--dark-blue);
}

.field_icon.hs_pass {
  left: inherit;
  right: 12px;
}

.form_field.pass input {
  padding-right: 42px;
}

.material-symbols-outlined {
  color: var(--icon-color);
}

.tab_btn_group .btn_single .active .btn_border {
  background: var(--dark-blue);
  color: var(--white-color);
}

.tab-buttons {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 12px;
  padding-bottom: 30px;
}

.tab-buttons button.active {
  font-weight: bold;
  color: var(--white-color);
  background: var(--dark-blue);
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}





span.material-symbols-outlined.info.pointer {
  position: absolute;
  top: -20px;
  right: -10px;
  font-size: 18px;
  background: #80808029;
  border-radius: 50%;
  color: black;
}

.field_name,
.field_value {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 16px;
  word-break: break-all;
}

.field_single {
  display: flex;
  gap: 4px;
  align-items: center;
  margin-top: 3px;
}

.card_fields {
  margin-top: 50px;
}

.card_fields .material-symbols-outlined {
  font-size: 20px !important;
}

.spacer {
  width: 100%;
  height: 5px;
  background: #d3e0f1;
  margin-top: 25px;
}

.relative {
  position: relative;
}

.edit_bg img {
  width: 100%;
}

.edit_bg img.edit_sq_icon {
  width: 16px;
}



.edit_bg {
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--dark-blue);
  position: absolute;
  top: -20px;
  right: 25px;

}

@media only screen and (max-width: 575px) {

  .ab_icon {
    width: 45px !important;
    height: 45px !important;
  }

  .ab_icon.call_icon {
    right: 20px;
    bottom: 70px !important;
  }

  .d_desktop.w_parent .w_icon {
    width: 45px !important;
    height: 45px !IMPORTANT;
  }

  a.ntf_icon.w_icon {
    /* bottom: 78px !important; */
    right: 20px;
  }

  .ab_icon .material-symbols-outlined {
    font-size: 34px !important;
  }
}