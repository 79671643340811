.styled-input {
    /* width: 100%; */
    padding: 10px;
    font-size: 16px;
    border: 2px solid var(--click-color);
    border-radius: 10px;
    /* margin-top: 10px; */
    /* box-sizing: border-box; */
    outline: none;
    transition: border-color 0.3s ease-in-out;
}

.styled-input:focus {
    /* border-color: var(--click-color); */
    border: 2px solid var(--pink);
    /* Change the border color when focused */
}


/* Tablet (portrait) and above */
@media only screen and (max-width: 600px) {
    .styled-input {
        /* width: 100%; */
        padding: 0px 5px;
        font-size: 14px;
    }
}

/* Desktop and above */
@media only screen and (min-width: 992px) {}

/* Large desktop and above */
@media only screen and (min-width: 1200px) {}