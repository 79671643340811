.navbar {
  /* position: fixed; */
  width: 100%;
  padding: 6px 0 8px 0;
  box-sizing: border-box;
  display: flex;
  background: transparent;
}

.navbar.fixed {
  background-color: var(--page-bg);
  box-shadow: 0 0 10px 0 rgba(118, 114, 114, 0.2);
}

.navbar>.container-fluid {
  align-items: inherit !important;
}

.navbar ul {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}


.navbar .logo img {
  margin-right: 10px;
  width: 220px;
  /* height: 50px; */
}


.navbar {
  /* position: relative; */
  background-color: #f0f0f0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  /* margin-bottom: 100px; */
}


.navbar {
  position: fixed;
}

.navbar.fixed .logo img {
  background: inherit;
  padding: inherit;
}

.navbar a {
  color: #333;
  text-decoration: none;

}

.navbar-notification-div {
  position: relative;
  display: flex;
  align-items: center;
}

.navbar-notification-div span {
  font-size: 1.5rem;
  color: var(--pink);
  cursor: pointer;
}

.navbar-notification-div div {
  position: absolute;
  top: 2px;
  right: 3px;
  width: 8px;
  height: 8px;
  background: red;
  border-radius: 50%;
  pointer-events: none;
}

.navbar-laptop-menu-links-div {
  display: flex;
  /* align-items: center; */
}

.navbar-laptop-menu-links-div .navbar-laptop-menu-links {
  display: flex;
  align-items: center;
  /* padding: 0 20px; */
}


.navbar-laptop-menu-links-div .navbar-laptop-menu-links div {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 7px 15px;
  border-radius: 50px;
  /* margin: 0px 2px; */
}

.navbar-laptop-menu-links-div .navbar-laptop-menu-links div span {
  color: var(--pink);
  transition: 0.5s;
  font-variation-settings:
    'FILL' 0;
}

.fixed .navbar-laptop-menu-links-div .navbar-laptop-menu-links div span {
  color: var(--pink);
  transition: 0.5s;
}

.active_menu {
  background: var(--click-color);
  border-bottom: 2px solid var(--click-color) !important;
}

.active_menu span.material-symbols-outlined,
.active_menu h1 {
  color: var(--same-white-color) !important;
}




.navbar-laptop-menu-links-div .navbar-laptop-menu-links div h1 {
  position: relative;
  top: 1px;
  font-size: 16px;
  margin: 0;
  font-weight: 700;
  color: var(--pink);
  /* padding-left: 5px;  */
  transition: 0.5s;
}

.fixed .navbar-laptop-menu-links-div .navbar-laptop-menu-links div h1 {
  color: var(--pink);
}

.navbar-laptop-menu-icons-div {
  display: flex;
  align-items: center;
}

.navbar-user-icon {
  position: relative;
  top: 1px;
  width: 35px;
  height: 35px;
  background: var(--lightblue-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 20px;
  overflow: hidden;
}

.navbar-user-icon span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.4rem;
}

.np_inner {
  display: flex;
  gap: 6px;
  align-items: center;
}

.np_inner img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.np_inner h5 {
  color: white;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 6px;
  /* position: relative; */
  /* top: 2px; */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 122px;
}

.navbar_profile {
  display: flex;
  align-items: center;
  background: var(--click-color);
  padding: 3px 3px 3px 14px;
  border-radius: 50px;
}

.navbar_profile.active_menu {
  background: var(--dark-blue);
}

.navbar-user-icon img {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px !important;
  height: 35px !important;
  border-radius: 50%;
  padding: 1px;
}

.navbar-laptop-menu-icons-div button {
  margin-left: 20px;
  font-size: 0.8rem;
  height: 30px;
  padding: 0 10px;
  border-radius: 30px;
  font-weight: 700;
}

.navbar-laptop-menu-icons-div .navbar-laptop-menu-icons-div-hamburger-icon {
  padding-left: 20px;
}

.navbar-laptop-menu-icons-div .navbar-laptop-menu-icons-div-hamburger-icon span {
  position: relative;
  top: 3px;
  color: var(--lightblue-color);
  cursor: pointer;
}

.navbar-menu-expand-div {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--red-color);
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 80px 0 20px;
  z-index: 999;
  overflow: hidden;
  pointer-events: none;
  height: 0;
  transition: 1s;
}

.navbar-menu-expand-div.open {
  pointer-events: all;
  height: 100%;
}

.navbar-menu-expand-div .navbar-menu-expand-div-close-btn {
  position: absolute;
  top: 22px;
  right: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar-menu-expand-div .navbar-menu-expand-div-close-btn span {
  font-size: 1.6rem;
}

.navbar-menu-expand-div .navbar-menu-expand-div-menu {
  position: relative;
  padding: 5px 0;
  margin: 20px 0;
  cursor: pointer;
}

.navbar-menu-expand-div .navbar-menu-expand-div-menu h1 {
  font-size: 2.2rem;
  text-align: right;
  color: #fff;
}

.navbar-menu-expand-div .navbar-menu-expand-div-menu div {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 4px;
  background: #fff;
  border-radius: 4px;
  transform: scaleX(0);
  transform-origin: right;
  transition: 0.5s;
}

@media only screen and (max-width: 575px) {
  .navbar .logo img {
    margin-right: 10px;
    width: 165px;
  }
}

/* Media query for screens between 768px and 1200px */
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .navbar-laptop-menu-links-div .navbar-laptop-menu-links div {
    padding: 7px 10px;
    /* margin: 0px 2px; */
  }
}