.popup-div {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.popup-div.open {
    display: flex;
    opacity: 1;
}

.popup-div-content {
    background: linear-gradient(135deg, #ffffff, #f9f9f9);
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    width: 400px;
    text-align: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 1;
    transform: translateY(0);
}

.popup-div.open .popup-div-content {
    transform: translateY(-10px);
}

.popup-div-message {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

.popup-div-textarea {
    width: 100%;
    height: 120px;
    margin-top: 10px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.popup-div-textarea:focus {
    border-color: var(--click-color);
    outline: none;
}

.popup-div-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.popup-div-btn {
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.popup-div-confirm-btn {
    background-color: var(--click-color);
    color: white;
}

.popup-div-confirm-btn:hover {
    background-color: var(--click-color);
    transform: translateY(-2px);
}

.popup-div-cancel-btn {
    background-color: var(--grey-dark);
    color: white;
}

.popup-div-cancel-btn:hover {
    background-color: var(--grey-dark);
    transform: translateY(-2px);
}