footer {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--same-dark-blue);
    padding-top: 185px;
    position: relative;
    padding-bottom: 70px;
    margin-top: 140px;
}

.newsletter-wrap {
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6705882353);
    background-color: var(--card-bg);
    border-radius: 10px;
    position: absolute;
    top: -80px;
    left: 0;
    right: 0;
    max-width: 1105px;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
}

.newsletter-item h2 {
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 15px;
    color: var(--black-color);
}

.newsletter-item p {
    margin-bottom: 0;
    color: var(--black-color);
    font-size: 15px;
    max-width: 550px;
    margin-left: 0;
}

.newsletter-item .newsletter-form {
    position: relative;
}

.newsletter-item .newsletter-form {
    position: relative;
}

.newsletter-item .newsletter-form .form-control {
    height: 75px;
    border-radius: 10px;
    border: 1px solid var(--dark-blue);
    padding-left: 25px;
    font-size: 18px;
}

.newsletter-item .newsletter-form .newsletter-btn {
    position: absolute;
    top: 15px;
    right: 7px;
    transition: 0.5s all ease;
}

.tabby-tamara-payment-parent {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tabby-tamara-payment-parent .tabby-logo {
    width: 60%;
    height: 60px;
}

.tabby-tamara-payment-parent .tamara-logo {
    width: 60%;
    height: 60px;
}

.footer-item {
    margin-bottom: 30px;
}

.footer-item .footer-contact h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 30px;
}

.footer-item .footer-contact ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-contact ul li {
    list-style-type: none;
    display: flex;
    color: #ffffff;
    font-weight: 500;
    padding-left: 0px;
    gap: 8px;
    align-items: center;
}

.footer-item .footer-contact ul li span {
    /* width: 250px; */
    letter-spacing: 1px;
}

.footer-item .footer-contact ul li i {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 42px;
    transition: 0.5s all ease;
}

.footer-item .footer-contact ul li a {
    display: block;
    color: #ffffff;
    margin-bottom: 7px;
}

.footer-item .footer-contact ul li a:last-child {
    margin-bottom: 0;
    /* letter-spacing: 1px; */
}

.footer-item .footer-quick h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 30px;
}

.footer-item .footer-quick ul {
    margin: 0;
    padding: 0;
}

.footer-item .footer-quick ul li {
    list-style-type: none;
    display: block;
    margin-bottom: 20px;
    color: #ffffff;
}

.footer-item .footer-quick ul li:hover {
    color: #ffffff;
}

.footer-item .footer-quick ul li a {
    display: block;
    font-weight: 500;
    font-size: 15px;
    color: #ffffff;
}

/* .footer-quick: ho {
    
} */

.footer-item .footer-feedback h3 {
    color: #ffffff;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 30px;
}

.footer-item .footer-feedback .form-group {
    margin-bottom: 20px;
}

.column-parent {
    display: flex;
    justify-content: center;
}

.footer-item .footer-feedback .form-group .form-control {
    font-size: 15px;
    background-color: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7803921569);
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 15px;
    color: #ffffff;
}

.footer-item .footer-feedback .feedback-btn {
    font-weight: 600;
    font-size: 14px;
    background-color: #ffffff;
    padding: 12px 35px;
    border-radius: 10px;
    color: var(--dark-blue);
    transition: 0.5s all ease;
}

.footer-img-logo {
    width: 180px;
    border-radius: 10px;
    margin-top: -60px;
}

.copyright-area {
    background-color: #012860e3;
}

.copyright-area .copyright-item {
    padding-top: 20px;
    padding-bottom: 20px;
}

.copyright-area .copyright-item p {
    margin-bottom: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

img.footer_icon {
    width: 34px;
}

.footer-contact li {
    position: relative;
}

.second_address {
    margin-top: 40px;
}

.social_links {
    margin: 10px;
}

.youtube_icon {
    height: 38px;
}

.insta_icon {
    height: 35px;
}

.threads_icon {
    height: 40px;
}

.facebook_icon {
    height: 35px;
}

.twitter_icon {
    height: 40px;
}

.snapchat_icon {
    height: 38px;
}

/* For 1366 Resolution */
/* @media only screen and (min-width: 1030px) and (max-width: 1400px) {
    .social_links {
        margin: 7px;
    }
} */

/* For 1024 Resolution */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .copyright-area .copyright-item {
        padding-bottom: 68px;
    }

    .footer-img-logo {
        width: 150px;
        margin-top: -30px;
    }
}

/* For 1024 Resolution */
@media only screen and (min-device-width : 575px) and (max-device-width : 768px) {

    .footer-img-logo {
        width: 150px;
        margin-top: 30px;
    }

}

@media only screen and (max-width:575px) {
    footer {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-top: 190px;
    }

    img.footer_icon {
        width: 40px;
    }

    .tabby-tamara-payment-parent {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-top: 15px;
    }

    .footer-img-logo {
        width: 160px;
        border-radius: 10px;
        margin-top: 50px;
    }

    .column-parent {
        justify-content: left;
    }

    .newsletter-item h2 {
        font-size: 18px;
    }

    .newsletter-item .newsletter-form .form-control {
        height: 45px;
        font-size: 15px;
        padding-left: 10px;
    }

    .newsletter-item .newsletter-form .newsletter-btn {
        top: 4px;
        right: 5px;
    }

    .newsletter-wrap {
        height: 200px;
        top: -190px;
        padding: 20px;
    }

    .social_links {
        margin: 6px;
    }

    .copyright-area .copyright-item {
        padding-bottom: 68px;
    }
}