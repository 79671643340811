.dental_service_name {
  font-size: 22px;
  color: var(--black-color);
  margin-top: 15px;
  text-transform: capitalize;
  letter-spacing: 1.5px;
}
.dental_imgs_ads {
  width: 100%;
  border-radius: 10px;
  margin-top: 30px;
}
@media screen and (max-width: 575px) {
  .dental_service_name {
    font-size: 15px;
  }
}
