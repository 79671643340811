/* .pg_services {
    margin-top: 0px;
} */

.about-banner h1 {
    font-family: unset;
    line-height: 40px;
    letter-spacing: -1px;
    font-weight: 800;
    font-style: normal;
    color: #1a1a1a;
    font-size: 36px;
    font-display: swap;
}

.active-service {
    background-color: var(--click-color);
    /* Change this to your desired active color */
    color: #fff;
    /* Change this to your desired text color */
}

.services_banner_heading {
    color: var(--pink);
    max-width: 600px;
    margin-bottom: 8px;
    font-size: 51px;
    font-family: 'p_bold';
    text-transform: capitalize;
    max-width: 600px;
    background-image: linear-gradient(45deg, var(--dark-blue), var(--dark-blue), var(--pink), var(--gold));
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    /* color: transparent; */
    animation: animateText 10s linear infinite;
}

/* services icon section */
.services_icon_div {
    background-color: rgb(251, 251, 251);
    border-radius: 15px;
    padding: 20px;
    margin: auto;
    border: 10px solid #149aa2;
    box-shadow: 10px 10px 25px -4px;
    width: 80%;
}

.service_selct_choice {
    font-size: 35px;
    font-weight: 600;
    color: var(--pink);
    padding: 50px 0px 45px 75px;
}

/* .service_selct_choice {
    font-size: 35px;
    font-weight: 600;
    color: var(--pink);
    margin: 50px 0px 45px 75px;
} */

.service_selct_choice::after {
    position: absolute;
    content: "";
    display: flex;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
}

.select_by_choice_heading {
    font-weight: 600;
    margin-bottom: 50px;
    color: var(--pink);
    font-size: 25px;
}

.icon_top_row {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    /* height: 150px; */
}

.icon_top_row h5 {
    font-size: 18px;
    margin-top: 10px;
    font-weight: 600;
    letter-spacing: 1px;
}

.first_service_icon:hover {
    background-color: var(--click-color);
    transition-duration: 0.8s;
    /* border-radius: 20px; */
    cursor: pointer;
    color: white;
}

.second_service_icon:hover {
    background-color: var(--click-color);
    transition-duration: 0.8s;
    /* border-radius: 20px; */
    cursor: pointer;
    color: white;
}

.third_service_icon:hover {
    background-color: var(--click-color);
    transition-duration: 0.8s;
    /* border-radius: 20px; */
    cursor: pointer;
    color: white;
}

.fourth_service_icon:hover {
    background-color: var(--click-color);
    transition-duration: 0.8s;
    /* border-radius: 20px; */
    cursor: pointer;
    color: white;
}

.services_icon_images {
    height: 60px;
    /* margin: 40px; */
    cursor: pointer;
}

.right_top_row {
    background-color: rgb(251, 251, 251);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 90%; */
}

.right_row_parent_div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.services_quick_links {
    margin-left: 40px;
    font-weight: 600;

}

.services_icon_left_images {
    height: 70px;
    /* margin: 40px; */
    cursor: pointer;
    border-right: 1px solid gray;
    margin: 20px;
    /* background-color: red; */
    padding: 10px;

}

.parent_div {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.parent_div_cosmetology {
    margin-top: 6rem;
    margin-bottom: 3rem;
}

.services_heading p {
    font-size: 25px;
    font-weight: 600;
    color: var(--pink);
}

.services_heading h1:after {
    position: absolute;
    content: "";
    display: flex;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
    margin: 10px 0px 10px 0px;
}

#top_heading {
    font-size: 40px;
    font-weight: 700;
    color: var(--dark-blue);
}

.img_section {
    margin-top: 40px;
}

.services-img {
    border-radius: 15px;
    margin-top: 65px;
    max-width: 90%;
    height: 200px;
}

.img-parent h2 {
    font-size: 30px;
    font-weight: 700;
    margin: 15px;
    color: var(--black-color);
}

.img-parent h5 {
    color: var(--black-color);
    font-size: 18px;
    word-spacing: 1px;
    margin: 15px;
}

/* top services */
section.top_cities.sect_padding {
    padding-bottom: 0px;
    margin-top: 7rem;
}

.more-btn-info:hover {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 40px;
}

.more-btn-div {
    text-align: center;
    margin-top: 5rem;
}

.more-btn-info {
    width: 150px;
    color: white;
    border: 1px solid #20b2aa;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 40px 40px 40px 0px;
    font-weight: 500;
    transition: all 0.3s ease;
    background-color: #20b2aa;
}

section.top_cities .first_div {
    width: 28%;
}

section.top_cities .second_div {
    width: 72%;
}

.cities_single.title {
    height: 200px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    padding: 25px;
}

.cities_single.title:after {
    content: none !important;
}

.cities_single.title .section_title h3 {
    max-width: 203px;
    width: 100%;
    color: var(--pink);
    font-weight: 800;
    word-spacing: 3px;
    font-size: 30px;
    text-transform: capitalize;
}

.section_title_effect {
    font-size: 45px;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #00000061;
    font-weight: 800;
    line-height: 80px;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.cities_single.title .section_title_effect {
    -webkit-text-stroke-color: #ffffff;
    z-index: 0;
}

section.top_cities .second_div .first_row {
    display: flex;
    flex-wrap: wrap;
}

.cities_single.sr_img_1 {
    width: 66.66%;
    height: 280px;
}

.cities_single.sr_img_2 {
    width: 33.33%;
    height: 280px;
}

section.top_cities .second_div .second_row {
    display: flex;
    flex-wrap: wrap;
}

.cities_single.first_row_img {
    width: 33.333%;
    height: 320px;
}

.cities_single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

section.top_cities {
    display: flex;
    flex-wrap: wrap;
}

section.top_cities .first_div .cities_single.down {
    height: 400px;
}

.cities_single {
    position: relative;
    transition: 0.4s;
}

.cities_single:after {
    content: '';
    background: black;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.5;
}

.city_number {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    color: white;
    font-size: 100px;
    transition: 0.4s;
}

.cities_single:hover .city_number {
    color: rgb(0, 0, 0);
    opacity: 0.4;
    transition: 0.4s;

}

.cities_single h5 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    z-index: 9;
    color: white;
    font-size: 30px;
    text-transform: capitalize;
    opacity: 0.9;
    transition: 0.4s;
}

.cities_single h6 {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 9;
    color: white;
    font-size: 16px;
    background: black;
    padding: 10px 15px !IMPORTANT;
    border-radius: 20px;
    text-transform: capitalize;
    transition: 0.4s;
}

.cities_single:hover h6 {
    color: rgb(0, 0, 0);
    background: rgb(255, 255, 255);
    transition: 0.4s;
}

.cities_single:hover:after {
    opacity: 0;
    transition: 0.4s;
}

.direction_girl {
    height: 500px;
    position: absolute;
}

.direction_girl_dr_name {
    position: absolute;
    bottom: 0;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(to top, white, transparent);
}

.direction_girl_dr_name h2 {
    font-weight: 800;
    text-align: center;
    margin-top: 100px;
    color: black;
    font-family: sans-serif;
}

.direction_girl_dr_name p {
    font-weight: 800;
    text-align: center;
    color: var(--pink);
    font-size: 20px;
}

.sevices_direction_circle {
    height: 500px;
}

/* Servies Carousel */
.carousel_services_parent {
    margin-top: 80px;
}

.carousel_services_heading {
    margin: 0px 0px 50px 70px;
}

/* .carousel_services {
    position: relative;
} */
.carousel_img_parent {
    position: relative;
}

.services_carousel_img {
    width: 50%;
    height: 230px;
    margin: 10px;
}

.services_img_heaing {
    position: absolute;
    top: 18px;
    left: 18px;
    font-size: 16px;
    /* font-weight: 600; */
    background-color: black;
    color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 35px;
}

.services_img_status_heaing {
    position: absolute;
    top: 18px;
    right: 0px;
    font-size: 16px;
    /* font-weight: 600; */
    background-color: rgb(137 137 137);
    color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 35px;
}

/* add new services css */
.add_new_service_sec_parent {
    /* max-width: 80%; */
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-top: 50px;
    background-color: var(--click-color);
    border-radius: 20px;
    padding: 0px 20px 20px 20px;
}

.new_service_details_parent {
    display: flex;
    align-items: center;
    background-color: #dc5c7e4f;
    padding: 10px;
    border-radius: 20px;
    justify-content: space-between;
}

.new_service_img_div img {
    width: 100%;
    border-radius: 20px;
}

.add_new_srvic_heading {
    color: white;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    padding: 10px;
}

.select_new_srvic_heading {
    color: white;
    padding: 0px 0px;
    display: flex;
    align-items: center;
    padding: 5px 0px 10px 0px;
}

.services_cancel_and_add_btn {
    display: flex;
    justify-content: space-between;
}

.new_service_text_details_div {
    margin-left: 30px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
}

.new_service_text_details_child_div {
    margin-top: 80px;
}

.new_service_name_price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.new_service_name_price h5 span {
    color: white;
}

.new_service_add_img_btn {
    position: absolute;
    font-weight: 700;
    color: var(--click-color);
    /* background-color: aliceblue; */
    padding: 8px 10px;
    border-radius: 10px;
}

.btn_fill_service_parent_div {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 550px;
}

.btn_fill_service {
    background-color: #ffffff24;
    border-radius: 50px;
    color: white;
    margin: 5px 0px;
    display: flex;
    align-items: center;
    transition: background-color .3s ease;
    /* Add transition property */
}

.btn_fill_add_img_service {
    background-color: #3a363645;
    border-radius: 50px;
    color: var(--click-color);
    margin: 5px 5px;
    display: flex;
    align-items: center;
    padding: 8px 10px;
}

.selected_services_btun {
    background-color: var(--pink);
    /* Change to your desired pink color */
    /* Add any other styling for the selected state */
}

.add_services_booking_input_field {
    border: none;
    border-bottom: 1px solid #ddd;
    border-radius: 10px;
    color: #858181;
    padding: 15px;
    font-size: 17px;
    height: 40px;
    outline: none;
    /* padding: 0 40px 0 10px; */
    position: relative;
}

@media only screen and (max-width: 1199px) {
    .cities_single h5 {
        font-size: 22px;
        padding: 0px 5px;
    }

    .city_number {
        font-size: 65px;
    }

    section.top_cities.sect_padding {
        padding-top: 0px;
    }

    .btn_fill_service_parent_div {
        margin-top: 0px;
    }
}

@media only screen and (max-width: 991px) {
    section.top_cities {
        display: block;
    }

    section.top_cities .first_div {
        width: 100%;
        display: flex;
    }

    .cities_single.title {
        width: 50%;
        padding: 15px;
    }

    section.top_cities .first_div .cities_single.down {
        width: 50%;
    }

    section.top_cities .second_div {
        width: 100%;
    }

    .cities_single h6 {
        padding: 5px 10px !IMPORTANT;
        font-size: 12px;
    }

    .cities_single.sr_img_1,
    .cities_single.sr_img_2,
    .cities_single.first_row_img,
    .cities_single.title,
    section.top_cities .first_div .cities_single.down {
        height: 220px;
    }

    .services_banner_heading {
        font-size: 32px;
    }

    .new_service_text_details_div {
        margin-left: 0px;
    }

    .btn_fill_service_parent_div {
        margin-top: 0px;
    }

    .new_service_text_details_child_div {
        margin-top: 20px;
    }
}

@media only screen and (max-width: 600px) {
    .services_banner_heading {
        font-size: 26px;
        max-width: 250px;
        width: 100%;
    }

    .parent_div {
        margin: 0rem 0rem;
    }

    .parent_div_cosmetology {
        margin-top: 3rem;
        margin-bottom: 0rem;
    }

    #top_heading {
        font-size: 20px;
    }

    .img-parent h2 {
        font-size: 18px;
    }

    .img-parent {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img-parent h2 {
        /* text-align: left; */
    }

    .services-img {
        width: 100%;
        height: 130px;
    }

    .img-parent h5 {
        margin: -10px 0px 0px 15px;
        font-size: 15px;
    }

    .cities_single h5 {
        font-size: 16px;
        padding: 0px 10px !important;
    }

    .city_number {
        font-size: 45px;
    }

    .section_title_effect {
        font-size: 30px;

    }

    .carousel_services_heading {
        margin: 0px 0px 50px 10px;
    }

    .services_img_heaing {
        font-size: 12px;
        margin: -3px;
    }

    .icon_top_row h5 {
        font-size: 12px;
        margin-top: 10px;
        font-weight: 600;
        letter-spacing: 1px;
    }

    .services_icon_images {
        height: 40px;
        /* margin: 40px; */
        cursor: pointer;
    }

    .icon_top_row {
        /* height: 110px; */
    }

    .services_icon_div {
        /* height: 240px; */
        width: 95%;
    }

    .service_selct_choice {
        font-size: 25px;
        margin: -75px 0px 0px -50px;
    }

    .direction_girl {
        height: 400px;
        left: 105px;
    }

    .right_row_parent_div {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .sevices_direction_circle {
        height: 400px;
        /* margin-right: -30px; */
    }

    .direction_girl_dr_name {
        left: 95px;
        width: 80%;
    }

    .services_carousel_img {
        height: 150px;
    }

    .carousel_services_parent {
        margin-top: 50px;
    }

    .new_service_text_details_div {
        margin-left: 0px;
    }

    .btn_fill_service_parent_div {
        margin-top: 10px;
    }

    .new_service_name_price div h5 {
        font-size: 16px;
    }

    .new_service_text_details_child_div {
        margin-top: 40px;
    }

    .services_img_status_heaing {
        top: 15px;
        font-size: 12px;
    }

    .select_new_srvic_heading {
        padding: 15px 0px 0px 0px;
    }
}