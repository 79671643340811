/* search bar code   */
.search_bar {
    border: 1px solid var(--grey-color);
    /* max-width: 310px; */
    background: var(--light-blue-bg);
    border-radius: 10px;
    position: relative;
    padding: 0px 10px;
    overflow: hidden;
    width: 285px;
}

.filter_search {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 0px;
}

.week-year-month-btns-div {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.pgcb_banner img {
    width: 100%;
    object-fit: cover;
    object-position: top;
    aspect-ratio: 3/1.20;
}

.booking_list_pyment_edit_icon {
    border-radius: 50%;
    border: 1px solid var(--black-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    padding: 12px;
    margin-left: 5px;
}

.search_bar input {
    border: none;
    background: #edfbfb;
    padding: 10px 0px;
    position: relative;
    z-index: 10;
    width: 82%;
}

.filters-icon-user-list {
    display: none;
}

/* Add this CSS code to your component's stylesheet or a global stylesheet */
.overlay-stop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.no-scroll {
    overflow: hidden;
    /* Disable scrolling */
}

/* Add this CSS code to your component's stylesheet or a global stylesheet */
.animated-card {
    position: fixed;
    bottom: 500px;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
    border-radius: 15px 15px 0px 0px;
}

.card_fields {
    /* margin-top: 44px; */
    margin-top: 0px;
}

.search_icon {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--click-color);
    height: 44px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.search_icon span {
    color: var(--white-color);
}

p.nobooking {
    text-align: center;
    margin: 40px 0px;
    font-size: 20px;
    color: var(--pink);
    font-family: 'p_semibold';
}

/* search bar code   */



.filter_dropdown select {
    padding: 5px;
    border-radius: 30px;
}

/* search bar code   */
.bcard {
    margin-top: 20px;
    padding: 15px;
    background: var(--white-color);
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    border-radius: 20px;

    position: relative;
}

.bookinglist {
    /* padding: 20px 0px; */
}

.bookinglist .bcard_title {
    position: absolute;
    left: 0;
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 20px 0px 0px 0px;
    font-family: 'p_medium';
}


.bcard.bd_card .pay_status {
    font-size: 16px;
    font-weight: 900;
    color: var(--red-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: rotate(-25deg);
    opacity: 0.5;
}

.bcard.bd_card {
    padding-right: 62px;
    /* min-height: 303px; */
    margin-top: 40px;
}


.card_fields .b_date_time .material-symbols-outlined {
    font-size: 23px !important;
}

.card_fields h5 {
    text-align: center;
    font-size: 20px;
    font-weight: 900;
}

.b_date_time {
    display: flex;
}

.b_date_time .date {
    padding-right: 6px;
}

.b_date_time .time {
    padding-left: 6px;
}

.b_date_time .field_value {
    font-size: 18px;
    color: var(--black-color);
    font-family: 'p_bold';
}

.de_icon {
    background: var(--white-color);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.de_icon span.material-symbols-outlined {
    color: var(--pink);
    font-size: 24px;
}

.pay_btn .btn_fill {
    padding: 6px 22px !important;
}

.field_name {
    color: #8f8f8f;
}

.delete_edit i {
    font-size: 24px;
    color: var(--dark-blue);
}

.delete_edit {
    display: grid;
    gap: 6px;
    position: absolute;
    bottom: 9px;
    right: 8px;
}

.attachemtn-icon-badge-parent {
    position: relative;
}

.attachemtn-icon-badge {
    position: absolute;
    background-color: var(--dark-blue);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    left: 23px;
    top: 0px;
    color: var(--white-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.final_doctor_booking_card_parent {
    display: grid;
    /* background-color: #0074d9; */
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding-bottom: 70px;
    justify-content: center;
    background-color: #f1efef;
}

.final_doctor_booking_card {
    display: grid;
    background-color: white;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr auto;
    /* Adjust to two rows, where the second row expands */
    border-radius: 10px;
    padding: 10px 20px 5px 20px;
    margin-top: 20px;
    border: 1px solid grey;
    overflow: hidden;
    transition: height 0.3s ease;
}

.addbooking_booking_card_dr_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 2px solid var(--pink);
    object-fit: cover;
}

.hr-line {
    width: 100%;
    border: 1px solid #000;
    /* You can adjust the color and style as needed */
    grid-column: 1 / span 3;
    /* Span across all three columns */
}

.booking-collaps-heading {
    /* border-top: 1px solid var(--click-color); */
    color: var(--click-color);
    font-weight: 700;
    margin: 15px 0px -10px 15px;
}

.box-2 {
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.box-3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-2 p {
    margin-left: 10px;
}

.box-5 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 2/3;
    grid-column: 1/-1;
    /* height: 5px; */
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 1.2rem;
}

.box-4 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 3/4;
    grid-column: 1/-1;
    margin-top: -10px;
    display: flex;
    /* align-items: center; */
    justify-content: start;
}

.box-6 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 4/5;
    grid-column: 3/-1;
    /* margin-top: -100px; */
    display: flex;
    /* align-items: center; */
    justify-content: end;
}

.box-7 {
    justify-content: start;
    grid-row: 4/5;
    grid-column: 1/3;
    /* height: 10px; */
    /* margin-top: -20px; */
    display: flex;
    /* align-items: center; */
}

.box-8 {
    /* grid-area: 3 / 1 / 2 / 3; */
    grid-row: 4/5;
    grid-column: 2/4;
    /* margin-top: -20px; */
    display: flex;
    justify-content: end;
    /* align-items: center; */
}

.box-9 {
    grid-row: 5/6;
    grid-column: 1/-1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.box-10 {
    grid-row: 6/7;
    grid-column: 1/-1;
    display: flex;
    justify-content: end;
    align-items: center;
    /* margin-top: -15px; */
}

.box-11 {
    grid-row: 7/8;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -15px;
}

.box-12 {
    grid-row: 8/9;
    grid-column: 1/-1;
    display: flex;
    justify-content: space-between;
    background-color: var(--grey-light);
    align-items: center;
    border-radius: 5px;
    padding: 2px 10px;
}

.box-13 {
    grid-row: 9/10;
    grid-column: 1/-1;
    display: flex;
    justify-content: end;
    align-items: center;
    /* gap: 2; */
}

.box-14 {
    grid-row: 10/11;
    grid-column: 1/-1;
    display: flex;
    justify-content: start;
    align-items: center;
    /* gap: 2; */
}

.category {
    position: absolute;
    top: -14px;
    right: 75px;
    padding: 3px 12px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    font-size: 15px;
    text-transform: lowercase;
    font-family: 'p_medium';
}

.category.self {
    background: var(--dark-blue);
    color: var(--white-color);
}

.category.self .c_inner:before {
    background: var(--dark-blue);
}

.category.other {
    background: var(--gold);
    color: var(--black-color);
}

.category.other .c_inner:before {
    background: var(--gold);
}

.c_inner {
    position: relative;
}

.c_inner:before {
    content: '';
    width: 12px;
    height: 21px;
    position: absolute;
    top: -1px;
    left: -18px;
    transform: rotate(24deg);
    z-index: -1;
}

.help_single img {
    width: 30px;
}

.help_support {
    padding: 12px;
    display: grid;
    gap: 15px;
    border-radius: 0px 20px 0px 0px;
    position: absolute;
    top: 0;
    right: 0;
}

.bookinglist .pay_amount .field_name .material-symbols-outlined,
.bookinglist .pay_amount .field_value {
    font-size: 24px !important;
    color: var(--black-color);
    font-weight: 600;
}

.pay_amount {
    display: flex;
    align-items: center;
    position: relative;
    top: 4px;
    margin-right: 6px;
}

.pay_btn_amount .bda_card {
    display: flex;
    align-items: center;
}

.pay_btn_amount {
    display: flex;
    align-items: center;
    gap: 10px;
}

.bookinglist .field_single .edit_bg {
    top: -17px;
    right: -4px;
    width: 20px;
    height: 20px;
}

.pay_btn_amount {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
    padding: 6px 12px;
    border-radius: 0px 0px 20px 20px;
}

.pay_btn {
    padding-top: 40px;
}

.bookinglist .edit_bg img.edit_sq_icon {
    width: 10px;
}

@media only screen and (max-width: 767px) {
    .box-5 {
        font-size: 1rem;
    }

    .bcard {
        padding: 10px;
    }

    .filter_search {
        display: flex;
        flex-direction: column;
    }

    .pgcb_banner img {
        aspect-ratio: inherit;
        height: inherit;
    }

    .card_fields .field_single:last-child {
        padding-right: 0px;
    }

    .field_name,
    .field_value {
        font-size: 14px;
    }

    .search_y_year {
        display: none;
    }

    .filters-icon-user-list {
        display: block;
    }

    .filter_dropdown select {
        padding: 3px 14px;
    }

    .search_y_year_mobile {
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        margin-left: 15px;
        background-color: white;
    }

    .filter-user-status-selected-button {
        background-color: var(--pink);
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        margin-left: 15px;
        color: white;
        transition: background-color .1s ease, color 1s ease;
    }

    .filter-user-selected-button {
        background-color: var(--pink);
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        color: white;
        transition: background-color .1s ease, color 1s ease;
    }

    .search_roles_mobile {
        border: 1px solid rgb(127, 127, 127);
        border-radius: 30px;
        padding: 3px 15px;
        display: inline;
        background-color: white;
        transition: background-color .5s ease, color 1s ease;
    }

    .cancel_filter_btn_border {
        padding: 6px 30px;
        border: 2px solid var(--pink);
        border-radius: 10px;
        color: var(--pink);
        font-weight: 700;
        font-size: 16px;
        background-color: white;
        letter-spacing: 1px;
        font-family: p_bold;
    }

    .apply_filter_btn_border {
        padding: 6px 30px;
        background: var(--click-color);
        border: none;
        border-radius: 6px;
        color: var(--white-color);
        padding: 10px 35px;
        font-family: 'p_bold';
        letter-spacing: 1px;
        text-transform: capitalize;
    }

    .pay_btn {
        bottom: 10px !important;
        right: 20px !important;
    }

    .delete_edit {
        position: unset;
        justify-content: left;
        display: flex;
        margin-top: 11px;
        gap: 14px;
    }

    .b_date_time .field_value {
        font-size: 18px;
    }
}