.about-banner h1 {
    font-family: unset;
    line-height: 40px;
    letter-spacing: -1px;
    font-weight: 800;
    font-style: normal;
    color: #1a1a1a;
    font-size: 36px;
    font-display: swap;
}

.about_data_parent_div {
    margin: 50px 0px 50px 0px;
}

.row-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    align-items: center;
    gap: 30px;
    /* margin-bottom: 150px; */
}

.denta_about_img {
    width: 250px;
    border-radius: 10px;
}

.top_right_div {
    /* padding: 20px 0px 0px 50px;
    margin: 0px 0px 0px 40px; */
}

.about_img_div {
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.bottom_text {
    margin-top: 65px;
    color: var(--black-color);
}

#top-heading {
    font-size: 36px;
    color: var(--pink);
    line-height: 45px;
    text-align: left;
    font-weight: 800;
    font-family: unset;
}

#top-para {
    line-height: 25px;
    margin-top: 15px;
    color: var(--black-color);
}

.btn-info {
    border-radius: 30px;
    padding: 12px;
    width: 200px;
    color: white;
    font-weight: 600;
}


@media only screen and (max-width: 600px) {
    .row-parent {
        display: grid;
        margin-bottom: 80px;
    }

    .top_right_div {
        padding: 0px 0px 0px 0px;
        margin-top: 40px;
    }

    .denta_about_img {
        width: 200px;
        display: flex;
        margin: auto;
        align-items: center;
        justify-content: center;
    }

    .bottom_text {
        margin-top: 50px;
    }

    .pp_first_heading {
        margin-top: -50px;
    }
}

@media only screen and (min-width: 600px) {
    .row-parent {
        display: grid;
        /* margin-bottom: 80px; */
    }

    .top_right_div {
        padding: 0px 0px 0px 0px;
        /* margin-top: 40px; */
    }


}

@media only screen and (min-width: 992px) {
    .row-parent {
        display: flex;
        /* justify-content: center;
        align-items: center; */
    }
}