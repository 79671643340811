.calendar_booking_upper_heading_parent {
    background-color: #3E98CC;
    display: flex;
    height: 180px;
    justify-content: center;
    padding: 20px;
    /* max-width: 100%; */
    margin-top: 60px;
}

.calendar_booking_upper_heading_parent h6 {
    color: white;
    margin-top: 10px;
}

.calender_bookings_right_parent {
    background-color: #3E98CC;
    border-radius: 20px;
    padding: 15px 0px;
    margin: 40px 0px;
}

.calender_bookings_right_parent_top_line {
    width: 30%;
    border: 2px solid white;
    border-radius: 30px;
}

.doctor_detail_right_card {
    /* height: 100%; */
}

.calender_doctor_card_details {
    margin-top: -75px;
}

.calender_appointment_time_details {
    display: flex;
    /* width: 100%; */
}

.calendar_appointment_detail_crd {
    margin: 10px;
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    border-left: 6px solid #064684;
    padding-left: 5px;
    width: 100%;
    box-shadow: 1px 0px 67px -26px;
}

.calender_bookings_parent {
    margin-top: -100px;
    background-color: #064684;
    border-radius: 20px;
    color: white;
    box-shadow: 0px 2px 25px -8px black;
    height: 139%;
}

.calendar_appointment_detail_heading {
    background-color: #eaeff3;
    border-radius: 0px 10px 0px 0px;
    color: var(--click-color);
}

.calender_doctor-details_parent {
    margin-top: -100px;
}

.calender_dr_img {
    height: 200px;
    aspect-ratio: 2/2;
    border-radius: 10px;
}

@media (max-width: 575px) {
    .calendar_booking_upper_heading_parent {
        margin-top: 0px;
    }

    .calender_bookings_right_parent {
        margin-top: 0px;
    }

    .calender_dr_img {
        height: 140px;
        /* aspect-ratio: 2/2; */
        border-radius: 10px;
    }

    .calender_doctor_card_details {
        margin-top: 0px;
    }

    .calender_bookings_parent {
        margin-top: 30px;
    }

}

@media (min-width: 575px) and (max-width: 990px) {
    .calendar_booking_upper_heading_parent {
        margin-top: 0px;
    }

    .calender_bookings_right_parent {
        margin-top: 0px;
    }


    /* .doctor_detail_right_card {
        margin-top: 130px;
    } */

    .calender_doctor_card_details {
        margin-top: -50px;
    }

    .calender_bookings_parent {
        margin-top: 30px;
    }
}