/* .slots_add_booking_parent {
    padding: 80px 0px;
}

.setup-slot-tble-prnt {
    width: 100%;
}

.setup-slot-checkboxes {
    text-align: center;
}

.slot-adding-days-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 8px 20px;
    border-radius: 10px;
    border: 2px solid var(--click-color);
    font-weight: 700;
    width: 130px;
    color: var(--click-color);
}

.gap-continer {
    gap: 30px;
}

.slot-adding-days-selected-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--pink);
    padding: 8px 20px;
    border-radius: 10px;
    border: 2px solid var(--pink);
    font-weight: 700;
    width: 130px;
    color: var(--white-color);
}

.slot-booking-clndr-cls {
    border-radius: 15px;
    padding: 0px 15px;
    height: 35px;
    border: 1px solid var(--grey-dark);
    margin: -28px 0px 10px 0px;
}

.setup_slots_timing-div {
    font-weight: 700;
    color: var(--dark-blue);
}

@media only screen and (max-width: 575px) {

    .slots_add_booking_parent {
        padding-top: 10px;
        background-color: #f1efef;
    }

    .gap-continer {
        gap: 15px;
    }

    .setup_slots_timing-div {
        font-size: 16px;
    }
}

@media (min-width: 575px) and (max-width: 990px) {} */

.setslot_div_doctor_parent {
    background-color: var(--white-color);
    border-radius: 15px;
    margin-top: 100px;
}

.setslot_div_doctor_parent form {
    background-color: var(--card-bg);
}

.btn-dont-click-setup-slot {
    font-size: 13px;
}

.setup-slot-canedr {
    margin: 20px 0px;
    border-radius: 15px;
    padding: 0px 15px;
    height: 35px;
    border: 1px solid var(--grey-dark);
}

.booked-status-true-setupslot {
    margin: 5px;
    border: 2px solid var(--grey-dark);
    /* background-color: var(--click-color); */
    /* border: none; */
    border-radius: 6px;
    color: var(--grey-dark);
    padding: 8px 0px;
    font-family: 'p_bold';
    font-size: 14px;
    text-transform: capitalize;
    width: 115px;
}

.half-hour-button-setupslots {
    margin: 5px;
    border: 2px solid var(--click-color);
    /* background-color: var(--click-color); */
    /* border: none; */
    border-radius: 6px;
    color: var(--click-color);
    padding: 8px 0px;
    font-family: 'p_bold';
    font-size: 14px;
    cursor: none;
    text-transform: capitalize;
    width: 115px;
}

.booked-status-true-setupslot-demo {
    margin: 5px;
    border: 2px solid var(--grey-dark);
    border-radius: 6px;
    color: var(--grey-dark);
    padding: 2px 0px;
    font-family: 'p_bold';
    font-size: 10px;
    text-transform: capitalize;
    width: 75px;
}

.half-hour-button-demo {
    margin: 5px;
    border: 2px solid var(--click-color);
    border-radius: 6px;
    color: var(--click-color);
    padding: 2px 0px;
    font-family: 'p_bold';
    font-size: 10px;
    text-transform: capitalize;
    width: 75px;
    cursor: none;
}

.weekly_setup_slot_table_slots {
    background: var(--click-color);
    border: none;
    border-radius: 5px;
    color: var(--same-white-color);
    padding: 2px 5px;
    font-family: 'p_bold';
    letter-spacing: 1px;
    cursor: none;
    margin: 5px 5px;
}

.booked_true_slot_class {
    margin: 5px 5px;
    padding: 2px 5px;
    letter-spacing: 1px;
    cursor: none;
    border: 2px solid var(--grey-dark);
    border-radius: 6px;
    color: var(--grey-dark);
    font-family: 'p_bold';
}

.setup-slot-add-slot-btn {
    width: 20%;
}

/* start default edit input css */
.slots-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.slot-group {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
}

.slot-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.slot-inputs {
    display: flex;
    align-items: center;
}

.slot-inputs span {
    margin: 0 8px;
}

@media (max-width: 575px) {
    .setup-slot-canedr {
        border-radius: 15px;
        padding: 0px 15px;
        height: 35px;
        width: 100%;
        border: 1px solid var(--grey-dark);
    }

    .setup-slot-add-slot-btn {
        width: 50%;
    }

    .start_end_slots_text {
        font-size: 14px;
    }

    .booked-status-true-setupslot-demo {
        border: 2px solid var(--grey-dark);
        color: var(--grey-dark);
        font-size: 8px;
        width: 60px;
        cursor: none;
    }

    .half-hour-button-demo {
        border: 2px solid var(--click-color);
        color: var(--click-color);
        font-size: 8px;
        width: 60px;
        cursor: none;
    }

    .btn-dont-click-setup-slot {
        font-size: 12px;
    }
}