.pghome .filter_search {
    display: none;
}

.pghome .bookinglist {
    padding: 25px 0px;
}

.pghome .bookinglist .bcard {
    margin-top: 35px;
}

.three_cards {
    text-align: center;
    background-color: rgb(248, 246, 244);
    padding: 25px 10px 25px 10px;
}

.three_headings {
    font-size: 22px;
    line-height: 50px;
    color: #383838;
    font-weight: 800;
}

.three_cards p {
    margin: 10px 0px 25px 0px;
}

.static-btn {
    border: 2px solid #149aa2;
    padding: 10px 15px 10px 15px;
    border-radius: 25px;
}

.section-header {
    text-align: center;
    margin: 100px 0px 40px 0px;
}

.section-header h2 {
    font-size: 26px;
    margin-bottom: 0;
    font-weight: 500;
    font-weight: 600;
}

.section-header p {
    color: #757575;
    font-size: 16px;
    margin-top: 15px;
}

.highlight_service img {
    width: 100%;
    height: 180px;
    border-radius: 14px;
}

.card_inner_right {
    margin-left: 25px;
}

.card-h4 {
    font-size: 1.125rem;
    margin: 20px 0px 10px 0px;
}

.card_inner {
    display: flex;
    border-radius: 12px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 25px;
    flex-basis: 620px;
    text-align: left;
    margin-top: 1em;
}

.about-para {
    color: #272b41;
    font-family: "Poppins", sans-serif;
    font-size: 0.9375rem;
    margin: 10px 0px 10px 0px;
}

.offer_banner_parent {
    /* background-color: rgba(240, 91, 131, 0.7); */
    background: linear-gradient(135deg, rgba(240, 91, 131, 0.7), rgba(89, 113, 255, 0.7));
    /* margin: 5rem 0 5rem 0; */
    padding-top: 20px;
}

.offer_banner_parent h6 {
    margin: 0px 0px 10px 20px;
}

.offers-section-title {
    margin-bottom: 40px;
    color: var(--click-color);
    margin-top: 2rem;
}

.offers-section-title p {
    font-size: 20px;
    font-weight: 600;
    color: var(--pink);
    margin-bottom: 0;
    margin-left: 20px;
}

.section-title p {
    font-size: 25px;
    font-weight: 600;
    color: var(--pink);
    margin-bottom: 0;
}

.upcom-booking-home-parent {
    border: 1px solid var(--grey-dark);
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    width: 95%;
    height: 100px;
    margin: 60px auto;
}

.home-dte-child-div {
    background-color: var(--pink);
    height: 100%;
    color: var(--white-color);
    border-radius: 14px;
}

.home-dte-child-div div h1 {
    font-weight: 700;
}

.home-day-child-div {
    height: 100%;
    gap: 5px;
}

/* .home-up-upcom-text h4 {
    font-size: 20px;
}

.home-up-service-nm h5 {
    font-size: 16px;
} */
.home_right-aroe-ico {
    height: 100%;
    background-color: var(--grey-light);
    border-radius: 0px 15px 15px 0px;
}

.home_right-aroe-ico span {
    font-size: 40px;
}

.owl-carousel .owl-item img {
    border-radius: 10px;
}

.offers-section-title h2 {
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    margin-left: 20px;
    color: var(--dark-blue);
}

.offers-section-title h2:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
}

#offer_banner {
    text-align: center;
    margin-top: 3em;
}

#offer_img {
    width: 80%;
}

.about-area {
    padding: 100px 0px;
}

.about-item {
    margin-bottom: 30px;
}

.about-left {
    position: relative;
}

.about-left:before {
    position: absolute;
    content: "";
    top: -10px;
    left: -10px;
    width: 430px;
    height: 375px;
    border-radius: 10px;
    /* z-index: -1; */
    animation: a-one 5s infinite linear;
    background-color: var(--dark-blue);
}

@keyframes a-one {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(-20px, -20px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.about-left img {
    width: 100%;
    z-index: 1;
    border-radius: 10px;
    position: sticky;
}

@keyframes a-seven {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.about-left:after {
    position: absolute;
    content: "";
    bottom: -10px;
    right: -10px;
    width: 430px;
    height: 375px;
    border-radius: 10px;
    /* z-index: -1; */
    animation: a-two 5s infinite linear;
    background-color: var(--dark-blue);
}

@keyframes a-two {
    50% {
        transform: translate(10px, 10px);
    }
}

.about-right {
    padding-left: 40px;
    position: relative;
}

.about-right img {
    position: absolute;
    top: -170px;
    right: 0;
    z-index: -1;
    width: 500px;
    animation: a-three 5s infinite linear;
}

@keyframes a-three {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 35px);
    }

    100% {
        transform: translate(0, 0);
    }
}



/* .about-item p {
    margin-bottom: 25px;
} */

.about-item ul {
    margin: 0;
    padding: 0;
    margin-bottom: 40px;
}

.about-item ul li {
    list-style-type: none;
    display: block;
    font-size: 18px;
    font-family: p_semibold;
    color: var(--black-color);
    margin-bottom: 12px;
}

.about-item ul li span {
    display: inline-block;
    margin-right: 10px;
    color: var(--dark-blue);
    font-size: 23px;
    position: relative;
    top: 6px;
}

/* him her image section css start  */
.himherimg img {
    width: 100%;

}

.him img.herimg {
    display: none;
}

.her img.himimg {
    display: none;
}

.him_her_area {
    padding: 100px 0px;
    background-color: var(--white-color);
}


.himherbutton {
    text-align: center;
}

.himherbutton button {
    margin: 0px 8px 15px 8px;
    border: 3px solid transparent;
}

.him button.btn_fill.her {
    background: none;
    color: var(--click-color);
    border: 3px solid var(--click-color);
}

.her button.btn_fill.him {
    background: none;
    color: var(--click-color);
    border: 3px solid var(--click-color);
}



/* expertice area css start  */
.expertise-area {
    padding: 100px 0px;
}

.section-title {
    margin-bottom: 40px;
    color: var(--click-color);
}

.section-title h2 {
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    color: var(--dark-blue);
}

.section-title h2:before {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
}

.expertise-item {
    text-align: center;
}

.expertise-item a {
    display: block;
}

.expertise-item .expertise-inner {
    background: var(--card-bg);
    padding: 25px 15px 25px 15px;
    border-radius: 10px;
    margin-bottom: 25px;
    color: white;
}

.expertise-item .expertise-inner:hover .ei_circleimg {
    box-shadow: none;
}

.expertise-item .expertise-inner .ei_circleimg {
    display: block;
    width: 70px;
    height: 70px;
    line-height: 70px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;

    margin-bottom: 25px;
    box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.6509803922);
    background-color: #ffffff;
    transition: 0.5s all ease;
    position: relative;
    top: 8px;
}

.expertise-item .expertise-inner h1 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 14px;
    color: var(--black-color);
}

.expertise-item .expertise-inner p {
    margin-bottom: 0;
    color: var(--black-color);
    text-transform: capitalize;
}

.expertise-item .expertise-right {
    padding-left: 80px;
    position: relative;
}

.expertise-item .expertise-right:before {
    position: absolute;
    content: "";
    top: -10px;
    left: 70px;
    width: 430px;
    height: 380px;
    z-index: 0;
    border-radius: 10px;
    animation: a-one 5s infinite linear;
    background-color: var(--dark-blue);
}

.expertise-item .expertise-right:after {
    position: absolute;
    content: "";
    bottom: -10px;
    right: -10px;
    width: 430px;
    height: 380px;
    z-index: 0;
    border-radius: 10px;
    animation: a-two 5s infinite linear;
    background-color: var(--dark-blue);
}

.expertise-item .expertise-right img {
    width: 100%;
    z-index: 33;
    position: relative;
    /* animation: a-seven 10s infinite linear; */
}

.ei_circleimg img {
    height: 45px;
}


@keyframes fadeInUP {
    0% {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    100% {
        opacity: 1;
        transform: translateZ(0);
    }
}

@keyframes a-seven {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

/* dental services css start  */
section.dental_services {
    padding: 100px 0px;
    /* background: ; */
}

.ds_single {
    padding: 10px;
    min-height: 165px;
    display: flex;
    align-items: center;
    justify-content: center;
}

section.dental_services .service_icon img {
    height: 80px;
}

section.dental_services h1.service_name {
    font-size: 15px;
    font-family: 'p_semibold';
    color: var(--black-color);
    margin-top: 15px;
    text-transform: capitalize;
    letter-spacing: 1.5px;
}

section.dental_services .ds_inner {
    display: flex;
    flex-wrap: wrap;
}

.ds_single {
    text-align: center;
    padding: 10px;
    width: 16.666%;
    aspect-ratio: 1/1;

}

/* blog section css start  */
section.blog-area {
    padding: 100px 0px;
    background: #f9fbff;
}

.blog-item {
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.4196078431);
}

.blog-item .blog-top {
    overflow: hidden;
}

.blog-item .blog-top a {
    display: block;
}

.blog-item .blog-top img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    transition: 0.5s all ease;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.blog-item .blog-bottom {
    padding-top: 35px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 35px;
}

.blog-bottom h3 a {
    text-transform: capitalize;
}

.blog-item .blog-bottom h3 a {
    font-weight: 600;
    font-size: 21px;
    color: #232323;
    margin-bottom: 12px;
    transition: 0.5s all ease;
    line-height: 1.4;
    display: block;
}

.blog-item .blog-bottom p {
    color: #232323;
    margin-bottom: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-item .blog-bottom ul {
    margin: 0;
    padding: 0;
    padding-top: 30px;
    border-top: 1px solid #b1b8ed;
}

.blog-item .blog-bottom ul li {
    display: inline-block;
    list-style-type: none;
    color: var(--dark-blue);
    font-weight: 500;
    font-size: 15px;
}

.blog-item .blog-bottom ul li a {
    display: block;
    color: var(--dark-blue);
    font-weight: 500;
    font-size: 15px;
    pointer-events: none;
}

.our_journey_services_heading {
    font-size: 17px;
}

.blog-item .blog-bottom ul li a i {
    display: inline-block;
    font-size: 22px;
    position: relative;
    top: 4px;
    transition: 0.3s all ease;
}

.blog-item .blog-bottom ul li:last-child {
    float: right;
}

.himherimg.mobile {
    display: none;
}

@media only screen and (min-width: 1200px) {
    .ds_single.dsb_r {
        border-right: 1px solid var(--grey-color);
    }

    .ds_single.dsb_b {
        border-bottom: 1px solid var(--grey-color);
    }
}

@media only screen and (min-width: 992px) {
    .blog-item {
        min-height: 535px;
    }
}

@media only screen and (max-width: 1199px) {
    .ds_single.dsmb_b {
        border-bottom: 1px solid var(--grey-color);
    }

    .ds_single.dsmb_r {
        border-right: 1px solid var(--grey-color);
    }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
    h2.banner_heading {
        font-size: 32px;
    }

    .ds_single {
        width: 33.33%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about-left:before {
        top: -10px;
        left: -10px;
        width: 430px;
        height: 375px;
    }

    .newsletter-wrap {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 25px;
        padding-right: 25px;
        max-width: 680px;
    }

    .about-left:after {
        bottom: -10px;
        right: -10px;
        width: 430px;
        height: 375px;
    }

    .about-item h2 {
        font-size: 26px;
        margin-bottom: 10px;
    }

    .about-right img {
        right: 0;
        width: 390px;
    }

    .about-item p {
        margin-bottom: 25px;
    }

    .about-item ul {
        margin-bottom: 35px;
    }

    .about-item ul li {
        font-size: 15px;
        margin-bottom: 12px;
    }

    .about-item ul li span {
        margin-right: 8px;
        font-size: 20px;
    }

    .about-right {
        padding-left: 0;
    }
}

@media only screen and (max-width: 991px) {
    .counter-item img {
        height: 38px;
    }

    .tab_reverse {
        flex-direction: column-reverse;
    }

    .expertise-item .expertise-right:before,
    .expertise-item .expertise-right:after {
        content: none;
    }

    .expertise-item .expertise-right {
        padding-left: 0px;

    }

    .expertise-item.left {
        margin-top: 30px;
    }

    .counter-item h3 {
        font-size: 26px;
    }

    .counter-item p {
        font-size: 16px;
    }

    .counter-bg {
        padding-top: 15px;
        padding-bottom: 10px;
        margin-top: -45px;
    }
}

@media only screen and (max-width: 767px) {
    .himherimg.mobile {
        display: block;
    }

    .himherimg.desktop {
        display: none;
    }

    .home-up-upcom-text h4 {
        font-size: 20px;
    }

    .home-up-service-nm h5 {
        font-size: 16px;
    }

    .section-title p {
        font-size: 20px;
        font-weight: 600;
        color: var(--pink);
        margin-bottom: 0;
    }

    .section-title h2 {
        font-size: 18px;
        max-width: 290px;
    }

    section.blog-area {
        padding: 40px 0px;
    }

    .blog-item .blog-bottom {
        padding-top: 15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
    }

    .blog-item .blog-bottom h3 a {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .blog-item .blog-bottom ul {
        padding-top: 15px;

    }

    .blog-item .blog-bottom p {
        margin-bottom: 15px;
        font-size: 14px;
    }

    section.doctors-area {
        padding: 40px 0px;
        /* margin-top: 35px; */
    }

    .expertise-item .expertise-inner h3 {
        font-size: 18px;
    }

    .expertise-area {
        padding: 40px 0px;
    }

    .expertise-item .expertise-inner {
        padding: 15px;
    }

    .expertise-item .expertise-inner p {
        font-size: 14px;
    }

    .section-title {
        margin-bottom: 25px;
    }

    section.dental_services {
        padding: 40px 0px;
    }

    .ds_single .dss_inner button.btn_fill {
        padding: 8px 14px !important;
        font-size: 12px;
    }

    .pghome .bookinglist .row {
        gap: 40px;
    }

    .ds_single {
        width: 33.33%;
    }

    section.dental_services .service_icon img {
        height: 40px;
    }

    .bda_card {
        margin-top: 15px;
    }

    .ds_single {
        padding: 5px;

    }

    .ds_single {
        min-height: 120px;
    }

    section.dental_services h4.service_name {
        font-size: 12px;
        margin-top: 10px;
    }

    .about-right {
        padding-left: 0;
    }

    .about-right img {
        top: -35px;
    }

    .him_her_area {
        padding: 40px 0px;
    }

    .card_inner {
        display: grid;
    }

    .highlight_service img {
        width: 100%;
        height: 250px;
    }

    .about-left img {
        animation: unset;
    }

    .about-right img {
        right: 2px;
        width: 270px;
    }


    /* .about-item p {
        margin-bottom: 20px;
    } */

    .about-item ul {
        margin-bottom: 25px;
    }

    .about-item ul li {
        font-size: 15px;
        margin-bottom: 10px;
    }

    .about-item ul li span {
        margin-right: 6px;
        font-size: 18px;
    }

    .about-left:before,
    .about-left:after {
        content: none;

    }

}


@media only screen and (max-width: 575px) {
    .counter-item img {
        height: 32px;
    }

    .expertise-item .expertise-inner {
        min-height: 282px;
    }

    .our_journey_services_heading {
        font-size: 17px;
    }

    .counter-item h3 {
        font-size: 20px;
        margin-bottom: 4px;
        margin-top: 4px;
    }

    .counter-item p {
        font-size: 14px;
    }

    .counter-item {
        margin-bottom: 0;

    }

    .offers-section-title h2 {
        font-size: 18px;
        max-width: 290px;
    }

    .offer_banner_parent h6 {
        margin: -15px 0px 10px 20px;
    }

    .our_journey_services_heading {
        font-size: 12px;
    }

    .about-area {
        /* padding: 40px 0px; */
        padding-top: 70px;
        padding-bottom: 30px;
    }

    .upcoming_booking-bell-icon-gif {
        display: none;
    }
}