.navbar-mobile-bottom {
    position: fixed;
    background: var(--page-bg);
    bottom: -1px;
    width: 100%;
    z-index: 999;
    border-radius: 14px 14px 0px 0px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.navbar-mobile-bottom-menu {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}

.navbar-mobile-bottom-menu .navbar-mobile-bottom-menu-a {
    color: var(--click-color);
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    padding: 4px 0px;
}

.bm_single {
    width: 25%;
}

.bmp_single {
    width: 16%;
}

.navbar-mobile-bottom-menu-a.bm_single.active_bottom_menu {
    background: var(--pink);
    color: var(--same-white-color);
}

.navbar-mobile-bottom-menu-a.bm_single.active_bottom_menu span {
    color: var(--same-white-color);
}

.navbar-mobile-bottom-menu-a span {
    font-size: 24px;
    /* color: var(--pink); */
    color: var(--click-color);
}

.navbar-mobile-bottom-menu-a.active span {
    font-variation-settings:
        'FILL' 1
}

.navbar-mobile-bottom-menu-a small {
    font-size: 15px;
}

.navbar-mobile-bottom-menu a:nth-child(3) {
    width: 50%;
}

.navbar-mobile-bottom-menu .navbar-mobile-bottom-menu-a.active {
    color: #e10032
}


a.profile_icon {
    width: 40px;
    height: 40px;
    margin: 0 auto;
    background: var(--pink);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -26px;
}

a.profile_icon span {
    color: white;
}

.new-user:hover {
    text-decoration: none;
}

.new-user span {
    color: #fff;
}