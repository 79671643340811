.services-details-parent-div {
    padding: 100px 0px 60px 0px;
    min-height: 80vh;
}

.services-images-slider-div-child {
    background-color: white;
    padding: 50px;
    box-shadow: 1px 1px 10px -5px;
    border-radius: 16px;
    margin-top: -40px;
}

.bigimage_container .image-gallery-content .image-gallery-slide .image-gallery-image,
.bigimage_container .image-gallery-thumbnail .image-gallery-thumbnail-image {
    width: 100%;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.bigimage_container .image-gallery-thumbnail.active,
.bigimage_container .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid #161616;
}

.bigimage_container .image-gallery-thumbnail:hover {
    outline: none;
    border: 2px solid #161616;
}

.bigimage_container .image-gallery-thumbnails {
    padding: 0px;
    padding-top: 6px;
    border-radius: 0px 0px 16px 16px;
}

.bigimage_container .image-gallery-swipe {
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
}

.bigimage_container .image-gallery-left-nav .image-gallery-svg,
.bigimage_container .image-gallery-right-nav .image-gallery-svg {
    height: 40px;
    width: 40px;
    background: #00000057;
    border-radius: 50%;
}

.bigimage_container .image-gallery-icon {
    filter: none;
}

.bigimage_container .image-gallery-fullscreen-button,
.bigimage_container .image-gallery-play-button {
    padding: 8px;
}

.image-gallery-fullscreen-button .image-gallery-svg,
.image-gallery-play-button .image-gallery-svg {
    height: 40px;
    width: 40px;
    background: #00000057;
    padding: 7px;
    border-radius: 50%;
}

.product-details-pg-srvc-heading {
    line-height: 10px;
    text-align: center;
    margin-top: 30px;
}

.product-details-pg-srvc-heading div {
    color: white;
    font-size: 18px;
    font-weight: 600;
    margin-top: 23px;
}

.product-details-pg-srvc-heading div p {
    color: white;
    font-size: 13px;
    font-weight: 100;
}

.gallery-img-delete-icon {
    align-items: center;
    background: #fff;
    border: 1px solid #666;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 35px;
    justify-content: center;
    left: 10px;
    position: absolute;
    top: 10px;
    width: 35px;
    z-index: 999;
}

/* Define the CSS class for the red heart icon */
.heart-pink {
    color: red;
    /* Change the color to red */
    transition: color 0.3s;
    /* Add a smooth color transition */
}

.products-status-switch {
    transform: scale(0.7);
}

/* Heart icon styling */
.material-symbols-outlined {
    cursor: pointer;
    /* Add a pointer cursor to indicate it's clickable */
    font-size: 20px;
    /* Set the desired size for the heart icon */
}

.namesd {
    justify-content: space-between;
}

.services_details_bottom_details_parent {
    padding-bottom: 0px;
    margin-top: 20px;
}

.more_carousel_services_heading {
    margin: 0px 0px 50px 0px;
}

.services_details_bottom_details_child {
    margin: 0px 10px;
}

.product_edit_save_btn {
    padding: 3px 12px;
    background: var(--click-color);
    border: none;
    border-radius: 6px;
    color: var(--white-color);
    font-family: 'p_bold';
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-top: 7px;
}

.product-offer-carousel .slick-slide {
    transition: transform 0.3s ease;
}

.product-offer-carousel .slick-slide:hover {
    transform: scale(1.05);
}

.product-offer-carousel .product_offer_img_1 {
    width: 100%;
    border-radius: 10px;
    margin: 35px 0px;
    height: 250px;
}

/* radio button code start */
.radio-container {
    /* display: flex; */
    /* flex-direction: column; */
}

.service_details_radion_btn {
    background-color: var(--grey-light);
    border: 1px solid rgb(127, 127, 127);
    border-radius: 30px;
    padding: 3px 15px;
    /* display: inline; */
    margin: 5px 3px;
    color: var(--black-color);
    font-weight: 700;
    font-size: 16px;
    transition: background-color .1s ease, color 1s ease;
}

.radio_btn_checked {
    background-color: var(--click-color);
    border: 1px solid rgb(225, 225, 225);
    border-radius: 30px;
    padding: 3px 15px;
    /* display: inline; */
    margin: 5px 3px;
    color: white;
    font-weight: 700;
    font-size: 16px;
    transition: background-color .1s ease, color 1s ease;
}

.radio-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    display: none;
}

.radio_btun_icon {
    font-size: 30px;
}

.next_btn_back {
    display: none;
}


/* PRODUCT DETAILS USER PERMIISON POPUP START */
.product-details-right-list-parent {
    min-width: 600px;
}

.product-details-right-list {
    display: flex;
    flex-direction: column;
}

/* PRODUCT DETAILS USER PERMIISON POPUP END */

@media only screen and (max-width:575px) {
    .services-details-parent-div {
        padding-top: 80px;
    }

    .services-images-slider-div-child {
        padding: 15px;
    }

    .product-offer-carousel .product_offer_img_1 {
        margin: 14px 0px;
        height: auto;
    }

    .services_details_right_dv {
        margin-top: 40px;
    }

    .products-status-switch {
        transform: scale(0.6);
    }

    .next_btn_back {
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 999;
        background: #f5f5f5;
        padding: 12px 22px;
        display: block;
    }

    .back_book_btn_parent {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0px 0px;
    }

    .back-book-btn-real {
        background: var(--click-color);
        border: none;
        border-radius: 6px;
        color: var(--same-white-color);
        padding: 6px 40px;
        font-family: 'p_bold';
        letter-spacing: 1px;
        text-transform: capitalize;
    }

    .product-details-right-list-parent {
        min-width: 350px;
    }

    .product-details-right-list {
        display: none;
    }
}

/* For 1024 Resolution */
@media only screen and (min-device-width : 575px) and (max-device-width : 1024px) {
    .services_details_right_dv {
        margin-top: 40px;
    }

    .services-images-slider-div-child {
        padding: 15px;
    }
}