.more_contant_parnt_div {
    padding-top: 40px;
}

.more-expand-div {
    /* padding: 10px 0px; */
    padding-top: 40px;
}

.more-div-profile-div {
    position: relative;
    background: var(--lightblue-color);
    border-radius: 8px;
    /* margin: 0 20px; */
    display: flex;
    align-items: center;
    padding: 20px 10px;
}

.more-div-profile-div .more-div-profile-div-img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--cream-color);
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-expand-div .pp_card {
    /* max-width: 500px; */
    width: 100%;
}

.more-expand-div .pc_card {
    /* max-width: 1000px; */
    color: black;
    background: white;
    border-radius: 20px;
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    position: relative;
    overflow: hidden;
}

.more_display_mode_card {
    justify-content: space-between;
}

.more-div-profile-div .more-div-profile-div-img img {
    width: 100%;
    border-radius: 50%;
}

.more-div-profile-div .more-div-profile-div-img span {
    position: relative;
    top: 1px;
    font-size: 1.8rem;
    font-weight: 500;
}

.more-div-profile-div .more-div-profile-div-content {
    padding-left: 10px;
}

.more-div-profile-div .more-div-profile-div-content h1 {
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    padding-bottom: 5px;
    margin: 0;
}

.more-div-profile-div .more-div-profile-div-content h2 {
    font-size: 1rem;
    color: var(--cream-color);
    font-weight: 400;
    margin: 0;
}

.more-div-profile-div .more-div-profile-div-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: #fff;
}

.more-div-big-icon-div {
    border-radius: 8px;
    /* box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2); */
    /* margin: 0 10px; */
    background: var(--card-bg);
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 1023zpx;
    width: 100%;
    margin: 0 auto;
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    margin-top: 50px;
    margin-bottom: 5px;
}

.more-div-big-icon-div .more-div-big-icon-div-inner {
    width: 33.3% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.more-div-big-icon-div .more-div-big-icon-div-inner div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: var(--light-blue);
}

.more-div-big-icon-div .more-div-big-icon-div-inner div span {
    color: var(--dark-blue);
    font-size: 2rem;
    transition: 0.5s;
    font-variation-settings:
        'FILL' 0
}

.more-div-big-icon-div .more-div-big-icon-div-inner:hover div {
    background: var(--click-color);
}

.more-div-big-icon-div .more-div-big-icon-div-inner:hover div span {
    color: var(--white-color);
    font-variation-settings:
        'FILL' 1
}

.more-div-big-icon-div .more-div-big-icon-div-inner h1 {
    font-size: 0.9rem;
    font-weight: bolder;
    padding-top: 10px;
    color: var(--black-color);
}



.more-div-card {
    /* border: 1px solid #ddd; */
    padding: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}

.more-div-card .more-div-card-heading {
    font-size: 1.4rem;
    font-weight: bolder;
    padding-bottom: 8px;
}

.more-div-card-inner {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background: #f4f4f4;
    /* background: var(--cream-color); */
    border-radius: 4px;
    padding-left: 10px;
    margin: 10px 0;
}

.more-div-card-inner .more-div-card-inner-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgba(84, 204, 203, 0.5);
    /* background: var(--peach-color); */
    display: flex;
    align-items: center;
    justify-content: center;
}

.more-div-card-inner .more-div-card-inner-icon span {
    color: #222;
    /* color: var(--cream-color); */
    transition: 0.5s;
    font-size: 1.3rem;
    font-variation-settings:
        'FILL' 0
}

.more-div-card-inner:hover .more-div-card-inner-icon span {
    font-variation-settings:
        'FILL' 1
}

.more-div-card-inner .more-div-card-inner-content {
    width: calc(100% - 40px);
    padding: 0 20px;
}

.more-div-card-inner .more-div-card-inner-content h2 {
    font-size: 1rem;
    color: #444;
    font-weight: bolder;
    padding-bottom: 3px;
}

.more-div-card-inner .more-div-card-inner-content h3 {
    font-size: 0.7rem;
    color: #aaa;
    font-weight: bolder;
}

.more-div-card-inner .more-div-card-inner-arrow {
    position: absolute;
    top: 55%;
    right: 5px;
    transform: translateY(-50%);
}

.more-div-card-inner .more-div-card-inner-arrow span {
    font-size: 1.2rem;
    color: var(--lightblue-color);
    font-weight: bold;
}

@media only screen and (max-width: 767px) {
    .more_contant_parnt_div {
        padding-top: 80px;
    }

    .mauto {
        margin-left: auto;
        margin-right: auto;
    }

    .mlauto {
        margin-left: auto;
    }

    .more-div-big-icon-div {
        margin-top: 0px;
    }

    .more-upper-card {
        /* margin-top: 100px; */
        height: 100px;
        position: relative;
        border-radius: 0px 0px 30px 30px;
        background-color: var(--click-color);
    }

    .more-expand-div {
        padding-top: 10px;
    }

    .more-frontdesk-expand-div {
        margin-top: 50px;
    }
}