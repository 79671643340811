.propagentusersingle {
    box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
    background: var(--white-color);
    border-radius: 15px;
    padding: 15px;
    position: relative;
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    height: auto;
    /* Set the initial height for cards */
    /* overflow: hidden; */
    transition: height 0.3s;
}

.notification-card.expanded {
    height: auto;
    /* Set the height to auto when expanded */
}

.add_notification_parent {
    margin-top: 50px;
}

.notification_div_parent {
    padding: 80px 0px 50px 0px;
}

.propagentuser.propagentnotification .propagentusersingle {
    display: block;
    position: relative;
}

h2.p_title {
    font-size: 18px !important;
    margin-bottom: 4px !important;
    font-weight: 700;
}

h4.p_subtitle {
    font-size: 13px;
    color: #303030;
}

h4.notification_date {
    font-size: 12px;
    position: absolute;
    top: 6px;
    right: 10px;
    color: var(--pink);
}

h4.notification_date img {
    height: 26px;
    width: auto;
}

.propagentcontactdetail .propagentusersingle h5.name,
.propagentcontactdetail .propagentusersingle h6.phone_number {
    padding-right: 30px !important;
}

.propagentcontactdetail h4.notification_date {

    display: flex;
    flex-direction: column;
    gap: 6px;
}

.left .img_div img {
    object-fit: contain;
    width: 65px;
    height: 65px;
}

.propagentusersingle .left .img_div {
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.propagentusersingle .left {
    display: flex;
    align-items: center;

    width: 100%;
}

.propagentnotification .propagentusersingle .left {
    align-items: start;
    margin-top: 7px;
}

.propagentnotification .expand_line {
    position: absolute;
    font-size: 12px;
    color: #a976ff;
    font-family: 'r_medium';
    right: 10px;
    bottom: 6px;
}

.propagentnotification .propagentusersingle h6.phone_number {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.propagentusersingle h6.phone_number {
    font-size: 15px;
    color: grey;
    margin-top: 2px !important;
}

.propagentusersingle h5.name {
    font-size: 16px;
    word-break: break-all;
    font-weight: 700;
    color: var(--black-color);
}

.phone_number {
    font-size: 1rem;
    line-height: 1.4;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.expanded {
    display: -webkit-box;
    -webkit-line-clamp: unset;
}

.expand_line {
    cursor: pointer;
    color: var(--click-color);
    font-size: 0.8rem;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-width: 769px) {
    .pg_header {
        padding-top: 20px;
    }

    .add_notification_parent {
        margin-top: 10px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .pg_header {
        padding-top: 0px;
    }
}