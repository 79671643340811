    .paybanner img {
        width: 100%;

    }

    @media only screen and (min-width: 768px) {
        .paybanner img {

            object-fit: cover;
            object-position: bottom;
            aspect-ratio: 3/1.20;
        }
    }