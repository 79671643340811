.p_card {
    position: relative;
    border: 1px solid var(--grey-dark);
    background: var(--white-color);
    border-radius: 20px;
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    margin-top: 30px;
}

.p_card_header {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    gap: 7px;
    justify-content: space-between;
    padding-right: 56px;
}

.btnrepay button.btn_fill {
    background: #88b60c;
    border: none;
    border-radius: 25px;
    color: var(--white-color);
    padding: 5px 25px;
}

span.ra {
    display: flex;
    align-items: center;
}

span.material-symbols-outlined.rs {
    font-size: 32px;
    font-weight: 600;
}

span.pa {
    font-size: 32px;

    color: var(--dark-blue);
    font-family: p_semibold;
}

.p_card_body .d_single {
    margin-top: 3px;
}

.p_card_body {
    padding: 6px 15px;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-column-gap: 12px;
    background: var(--light-blue-bg);
    border-top: 1px solid var(--grey-dark);
    border-bottom: 1px solid var(--grey-dark);
}

.paymentunpaid .p_card_body {
    background: var(--light-rose-bg);
}

.label {
    font-size: 12px;
    color: var(--grey-dark);
}

.p_card_footer {
    padding: 5px;
    text-align: center;
    background: var(--light-blue);
    border-radius: 0px 0px 20px 20px;
    color: var(--black-color);
    font-size: 14px;
}


.value {
    font-size: 14px;
    color: var(--black-color);
    margin-top: -4px;
    font-family: 'p_semibold';
    /* word-break: break-all; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
}

.ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: var(--black-color);
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: linear-gradient(var(--light-click-color) 0%, var(--click-color) 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
}

.paymentunpaid .ribbon span {
    color: var(--black-color);
    background: linear-gradient(var(--rose) 0%, var(--pink) 100%);
}

.paymentunpaid .ribbon span::before {
    border-left: 3px solid var(--pink);
}

.paymentunpaid .p_card_footer {
    background: var(--pink);
    color: var(--white-color);

}

@media only screen and (max-width: 767px) {
    .p_card {
        margin-top: 20px;
    }
}