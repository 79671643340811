.profile_pg {
    margin: 40px 0px;
}

.profile-change-phone {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s;
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-change-phone:focus {
    border-color: #4a90e2;
}

.edit_bg {
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--dark-blue);
    position: absolute;
    top: -20px;
    right: 25px;
}

.edit_bg:hover {
    background-color: var(--pink);
    transform: scale(1.1);
}

.pp_card.rightside a {
    color: black;
}

.pp_card {
    background: var(--card-bg);
    border-radius: 20px;
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    position: relative;
    overflow: hidden;
}

.pp_card .title {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--pink);
    color: var(--same-white-color);
    padding: 10px;
    font-size: 16px;
    border-radius: 20px 0px 0px 0px;
}

.ppc_inner {
    margin-top: 40px;
}

.mydetail {
    display: flex;
    flex-wrap: wrap;
}

.mydetail .left,
.mydetail .right {
    width: 50%;
    padding: 45px 20px 20px 20px;
    background-color: var(--light-blue-bg);
}

.mydetail .left {
    background: var(--light-blue-bg);
}

.my_pic {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    border: 3px solid var(--dark-blue);
    margin: 0 auto;
    position: relative;

}

.my_pic .edit_icon {
    position: absolute;
    top: 3px;
    right: 3px;
}

.my_pic img.user_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
}

h3.my_name {
    font-size: 20px;
    color: var(--dark-blue);
    font-weight: 900;
    text-transform: capitalize;
    margin-top: 22px;
    text-align: center;
}

.c_name.cn_label.cn_label2 input {
    text-align: center;
    display: inline-block;
    color: var(--black-color);
    width: 75% !important;
    background: transparent;
    border: 1px solid black !important;
    border-radius: 10px;
    padding: 5px;
}

.c_name.cn_editlabel.cn_editlabel2 input {
    text-align: center;
    display: inline-block;
    /* max-width: 101px; */
    width: 75% !important;
    background: transparent;
    border: 1px solid var(--dark-blue) !important;
    border-radius: 6px;
    padding: 5px;
}

img.ed_icon.namenotdone {
    display: none;
}

.mydetail .right .profile-page-content h4 input {
    font-size: 18px;
    color: var(--dark-blue);
    margin-top: 15px;
}

.c_name.cn_label input {
    width: 100% !important;
    background: transparent;
    border: 1px solid transparent !important;
    border-radius: 6px;
    padding: 5px;
}

.c_name.cn_editlabel input {
    width: 75% !important;
    background: transparent;
    border: 1px solid var(--dark-blue) !important;
    border-radius: 6px;
    padding: 5px;
}

.mydetail .right .profile-page-content h4 .edit {
    right: 24px;
}

.myl_innercard {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.myli_single {
    border-radius: 20px;
    padding: 25px;
    width: 100%;
    margin: 20px auto;
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    text-align: center;
}

.profile_booking_number_card {
    margin: 20px 0px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.myli_single.bglight {
    background: var(--light-blue-bg);
}

.myli_single.bgdark {
    background: var(--light-blue-bg);
}

.myli_single.bgdark .profile-page-content h6 {
    color: var(--black-color);
}

.myli_single .profile-page-content h6 {
    font-size: 13px;
    margin-bottom: 6px;
    color: var(--black-color);
}

.myli_single .profile-page-content {
    margin-top: 20px;
}

.myli_single .profile-page-content:first-child {
    margin-top: 0px !important;
}

.myli_single .profile-page-content h4 {
    color: var(--dark-blue);
    font-weight: 800;
}

.mydetail .right .profile-page-content h6 {
    font-size: 13px;
    margin-bottom: 2px;
    color: var(--black-color);
}

.mydetail .right .profile-page-content h4 {
    font-size: 18px;
    color: var(--dark-blue);
    word-break: break-all;
    width: 160px;
}

.mydetail .right .profile-page-content {
    margin:0px 0px 30px 0px;
}

.edit_profile.title {
    right: 0;
    left: inherit;
    border-radius: 0px;
}

.edit_profile.title span {
    color: var(--white-color);
}

.rightside .myli_single.bglight {
    background: var(--light-blue-bg);
    box-shadow: none !important;
    max-width: 100%;
    border-radius: 0px;
    text-align: inherit;
    padding: 15px 25px;
    margin-top: 15px;
}

.rightside .myli_single .profile-page-content {
    display: flex;
    text-align: inherit;
    align-items: center;
}

.rightside .myli_single .profile-page-content h6 {
    font-size: 16px;
    margin-bottom: 0px;
    position: relative;
}

.rightside .myli_single .profile-page-content h6 span {
    position: absolute;
    top: -3px;
    right: 0;
}

.rightside .myli_single .profile-page-content .icon {
    width: 15%;
}

.rightside .myli_single .profile-page-content h6 {
    width: 100%;
    color: var(--black-color);
}

.icon_bg {
    background: var(--light-blue);
    display: inline-block;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
}

h6.more {
    padding: 25px 25px 5px 25px;
    color: var(--pink);
    font-weight: 700;
}

.profile-upload-input {
    display: none;
}

label.profile-upload-label {
    background: white;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

label.profile-upload-label img {
    width: 16px;
}

label.profile-upload-label {
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--dark-blue);
}

/* PHONE CHANDE POPUP CODE START */
.pop-up-change-number-div {
    opacity: 0;
    pointer-events: none;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    transition: 1s;
}

.pop-up-change-number-div.open {
    opacity: 1;
    pointer-events: all;
}

.pop-up-change-number-div .direct-div {
    position: relative;
    background: var(--white-color);
    padding: 20px;
    border-radius: 10px;
    max-width: 380px;
    width: 100%;
    text-align: center;
}

.edit-number {
    position: absolute;
    left: 65%;
    transform: translate(0, -27px);
}

.edit-number span {
    font-size: 1rem;
    color: var(--p-theme-purple);
    width: 20px;
    height: 20px;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;
}

.sroll-outter-div {
    width: 100%;
    overflow: hidden;
}

.sroll-outter-div .sroll-inner-div {
    width: 200%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sroll-outter-div .sroll-inner-div .scroll-div {
    width: 100%;
    padding: 20px 10px 10px 10px;
    transform: translateX(0%);
    transition: 1s;
}

.pgprofile-change-numb-inpt {
    border-radius: 10px;
    padding: 10px 5px;
    border: 1px solid var(--grey-light);
}

.change-number-button-div {
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
}


.pop-up-change-number-div .direct-div .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: var(--grey-light);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: var(--click-color);
    font-weight: 700;
    font-size: 1.1rem;
    cursor: pointer;
}

/* PHONE CHANDE POPUP CODE END */
@media (max-width: 850px) {

    .user-logo-parent-div .user-logo {
        width: 85px;
        height: 85px;
    }

    .user-logo-parent-div .user-logo .avatar {
        width: 80px !important;
        height: 80px !important;
    }
}

@media only screen and (max-width: 991px) {
    .pp_card.rightside {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 767px) {
    .mydetail .left {
        width: 100%;
        padding: 45px 10px 10px 10px;
    }

    .pp_card {
        margin-top: 0px;
    }

    .profile_pg {
        margin: 20px 0px;
    }

    .mydetail .right {
        width: 100%;
        padding: 15px;
        position: relative;
    }

    .mydetail .right .profile-page-content {
        margin: 30px 0px;
    }

    .mydetail .right .profile-page-content h4 {
        font-size: 16px;
    }

    .myl_innercard {
        padding: 0;
        flex-direction: column-reverse;
    }

    .myli_single .profile-page-content {
        margin-top: 0px;
    }

    .myli_single.bglight.mobile {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(2, 1fr);
    }

    .rightside .myli_single.bglight {
        padding: 15px;
        margin-top: 0px !important;
    }

    .rightside .myli_single.bglight:first-child {
        margin-top: 0px !important;
    }

    h6.more {
        padding: 15px 15px 5px 15px;
    }

    .mydetail .right .profile-page-content h4 input {
        font-size: 16px;
    }
}