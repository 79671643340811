.addbooking_dr_details_upper {
    background-color: white;
    width: 90%;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* bottom: -28px; */
}

.booking_services_book_now_btn {
    background: var(--click-color);
    border: none;
    border-radius: 6px;
    color: var(--white-color);
    padding: 10px 35px;
    font-family: 'p_bold';
    letter-spacing: 1px;
    text-transform: capitalize;
    font-size: 1.6rem;
    z-index: 999;
}

.add_booking_doctor_parent {
    padding-top: 80px;
}

.addbooking_dr_data_parent {
    padding: 0px;
}

.addbooking_dr_img_parent {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.addbooking_dr_img {
    width: 300px;
    height: 300px;
    margin: 0px 0px 30px 0px;
    border-radius: 50%;
    border: 4px solid var(--pink);
}

.active-toggle {
    opacity: .4;
    font-weight: 100;
}

.dr-details--lower {
    margin: 25px 0px 50px 0px;
    padding: 15px;
    background: var(--white-color);
    box-shadow: 0px 0px 16px -4px;
    /* border: 3px solid var(--pink); */
    border-radius: 20px;
    position: relative;
    max-width: 95%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
}

.time_button_parent_div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--click-color);
    /* border: none; */
    /* border-radius: 6px; */
    color: var(--white-color);
    padding: 10px 35px;
    font-family: 'p_bold';
    letter-spacing: 1px;
    text-transform: capitalize;
}

.time_button_parent_div span {
    color: var(--click-color);
    background-color: aliceblue;
    padding: 10px;
    font-size: 20px;
    border-radius: 50%;
    font-weight: 700;
}

.custom-datepicker-input {
    /* width: 80%; */
    display: flex;
    align-items: center;
    margin: 20px 0px 0px 0px;
    /* justify-content: center; */

}

/* Styles for the carousel container */
.carousel-container {
    width: 100%;
    overflow-x: scroll;
    /* Enable horizontal scrolling */
    scroll-snap-type: x proximity;
    /* Enable horizontal scroll snapping */
    white-space: nowrap;
    margin-top: 20px;

    /* Hide the scrollbar */
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 11 */
}

/* Hide the scrollbar for Webkit browsers (Chrome, Safari, Edge) */
.carousel-container::-webkit-scrollbar {
    display: none;
}

/* Styles for the carousel */
.carousel {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    /* Adjust the gap between buttons as needed */
    scroll-snap-align: start;
    /* Snap to the start of each button */
    transition: transform 0.3s ease-in-out;
}

.pcs_image_area {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.addbooking_selected_date_div p {
    font-weight: 600;
    color: var(--black-color);
    font-size: 17px;
    letter-spacing: 1px;
    margin: 50px 0px 15px 0px;
}

.add_booking_top_services_details_div {
    max-width: 95%;
    background-color: var(--card-bg);
    border-radius: 15px;
}

.bookpage_date_left_div {
    background-color: var(--page-bg);
    border-radius: 15px;
}

.bookpage_date_right_div {
    background-color: var(--page-bg);
    border-radius: 15px;
    padding: 10px;
}

.booking-page_collpse-btn {
    background-color: transparent;
    /* border-radius: 50%; */
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
}

.add_booking_top_services_details_div .add-booking-collps-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    color: var(--dark-blue);
    font-weight: 700;
}

.booking-page_collpse-btn span {
    font-size: 30px;
}

#a4headingOne {
    color: var(--dark-blue);
}

.other-detil-book-collps {
    justify-content: space-between;
    padding: 0px 10px;
}

.am_pm_parent_div {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.am-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px 0px;
}

.am-btn button {
    background: var(--click-color);
    border: none;
    font-size: 20px;
    color: var(--white-color);
    padding: 5px 25px;
    letter-spacing: 1px;
}

.pm-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 50px 0px;
}

.pm-btn button {
    background: var(--click-color);
    border: none;
    font-size: 20px;
    color: var(--white-color);
    padding: 5px 25px;
    letter-spacing: 1px;
}

.addbooking_dr_details_upper h6 {
    margin-right: 10px;
    padding: 0px 20px 0px 10px;
}



.addbooking-ampm-button-container {
    margin: -30px 0px 25px 0px;
    text-align: center;
}

.half-hour-button {
    margin: 5px;
    border: 2px solid var(--click-color);
    /* background-color: var(--click-color); */
    /* border: none; */
    border-radius: 6px;
    color: var(--click-color);
    padding: 8px 0px;
    font-family: 'p_bold';
    font-size: 14px;
    text-transform: capitalize;
    width: 115px;
}

.selectedTime {
    background-color: var(--click-color);
    margin: 5px;
    border-radius: 6px;
    color: var(--click-color);
    padding: 8px 0px;
    font-family: 'p_bold';
    text-transform: capitalize;
    width: 100px;
}

.selected {
    background-color: var(--pink);
    color: white;
    transition: .5s;
}

.addbooking-toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 40px 0px;
}

.button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.add_booking_colleps {
    padding: 0px;
    margin-top: 50px;
    margin-bottom: 0px;
}

.add_booking_colleps_child {
    padding: 0px;
    border: 2px solid var(--pink);
    box-shadow: 3px 3px 10px 1px;
}

.addbooking_group_icon {
    background-color: rgb(242, 242, 242);
    padding: 9px;
    border-radius: 50%;

}

.addbooking_reward_icon {
    background-color: rgb(242, 242, 242);
    padding: 9px;
    border-radius: 50%;
}

.add_booking {
    margin-top: 60px;
    background-color: #f1efef;
}

.bcard_title {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--dark-blue);
    color: var(--white-color);
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px 0px 0px 0px;
}



.bcard_inner {
    /* margin-top: -120px; */
}

.booking_for {
    padding: 20px;
    background: var(--white-color);
    box-shadow: rgb(3 70 135 / 30%) 0px 3px 8px;
    border-radius: 20px;
    max-width: 220px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
}

.booking_for h4 {
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    margin-bottom: 11px;
}

.form_field {
    margin-bottom: 14px;
    position: relative;
}

.field_icon {
    left: 12px;
    position: absolute;
    top: 10px;
}

.add-booking-days-button-parent {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Add your CSS file with the following styles */
.selectedDate {
    background-color: var(--pink);
    margin: 5px;
    border: 2px solid var(--click-color);
    border-radius: 5px;
    padding: 8px 10px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    width: 100px;
    transition: .5s;
}

.add-booking-days-button {
    /* margin: 8px;
    background-color: var(--click-color);
    border: none;
    border-radius: 5px;
    padding: 10px;
    color: white;
    font-weight: 600;
    font-size: 14px;
    width: 100px; */

    margin: 5px;
    border: 2px solid var(--click-color);
    border-radius: 5px;
    color: var(--click-color);
    padding: 8px 10px;
    font-family: 'p_bold';
    font-size: 14px;
    text-transform: capitalize;
    width: 100px;
}

.book-page-tntext-end {
    text-align: center !important;
    margin: 20px 0px 10px 0px;
}


.add-booking-clndr-cls {
    border-radius: 20px;
    padding: 0px 20px;
    height: 45px;
    border: 1px solid var(--grey-dark);
}

@media (max-width: 990px) {
    .addbooking-bcard {
        margin-top: 0px;
        display: inline-block;
    }

    .add_booking_colleps {
        display: block;
        padding: 0px;
        /* margin-top: 55px; */
    }

    .add_booking_colleps_child {
        padding: 0px;
        border: 2px solid var(--pink);
        box-shadow: 3px 3px 10px 1px;
    }


    .dr-details--lower {
        margin-top: 22px;
    }

    .final_doctor_booking_card_parent {
        padding: 0px 0px 60px 0px;
        grid-template-columns: 1fr;
    }
}


@media (max-width: 575px) {

    .add_booking {
        margin-top: 0px;
    }

    .bookpage_date_right_div {
        margin-top: 20px;
    }

    .bookpage_date_right_div {
        background-color: var(--same-white-color);
        border-radius: 15px;
        padding: 0px;
    }

    .addbooking_dr_img {
        width: 200px;
        height: 200px;
        margin: 0px 0px 0px 0px;
    }

    .book-page-tntext-end {
        margin: 20px 0px;
    }

    .add_booking_doctor_parent {
        padding: 60px 0px 50px 0px;
    }

    .final_doctor_booking_card_parent {
        padding: 0px 0px 60px 0px;
        grid-template-columns: 1fr;
    }

    .booking_services_book_now_btn {
        padding: 5px 10px;
        font-size: 1rem;
    }
}

/* 
input[type="time"] {
    position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background-color: red;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: transparent;
    opacity: 0;

} */