    .gallery_parent_div {
        margin-top: 0px;
        padding-top: 90px;
        background-color: rgb(249, 251, 255);
    }

    .fullscreen-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .fullscreen-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .show_gallery_data {
        background: linear-gradient(to right, #149aa2, #f05b83);
        padding: 5px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
    }

    .show_gallery_data_images {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px;
    }

    .gallery-fullscreen-icon {
        position: absolute;
        color: white;
        font-size: 25px;
        bottom: 10px;
        right: 15px;
        cursor: pointer;
        background-color: #00000063;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    /* Tablet (portrait) and above */
    @media only screen and (max-width: 600px) {
        .gallery_parent_div {
            margin: 25px 0px 30px 0px;
        }

        .gallery-fullscreen-icon {
            font-size: 20px;
            bottom: 5px;
            right: 10px;
            width: 30px;
            height: 30px;
        }
    }

    /* Desktop and above */
    @media only screen and (min-width: 992px) {}

    /* Large desktop and above */
    @media only screen and (min-width: 1200px) {}