.counter-bg {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: var(--card-bg);
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgba(221, 221, 221, 0.6509803922);
    margin-top: -95px;
    position: relative;
    z-index: 1;
}
    
.counter-bg-admin {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
}

.counter-item {
    margin-bottom: 8px;
    text-align: center;
}

.counter-item img {
    height: 65px;
}

.counter-item h3 {
    font-size: 42px;
    color: var(--click-color);
    margin-bottom: 8px;
    font-family: p_semibold;
    margin-top: 8px;
}

.counter-item p {
    font-size: 18px;
    margin-top: -5px;
    font-family: 'p_semibold';
    color: var(--black-color);
}

@media (max-width: 575px) {
    .mobwidth {
        width: 50% !important;
    }

    .counter .mbod_right {
        border-right: 1px solid var(--grey-color);
    }

    .mbod_bottom {
        padding-top: 30px;
        border-bottom: 1px solid var(--grey-color);
    }

    .ptop {
        padding-top: 30px;
    }

    .counter-bg {
        margin-top: inherit !important;
        margin: 30px 0px !important;
    }

    .counter-bg-admin {
        margin-top: inherit !important;
        margin: 30px 0px !important;
    }
}