.derma-ads-banner-div {
  margin-top: 0px;
}
.counter_derma-ads {
  margin-top: 130px;
}
.ads-category-card {
  position: relative;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 0px 0px 20px 0px;
}

.top-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.text-side {
  max-width: 70%; /* Adjust based on desired width */
}

.image-side {
  text-align: center;
  margin-left: 20px;
}

.award-ribbon-number {
  width: 300px; /* Adjust based on desired size */
}

.heading_below-underline {
  font-size: 30px;
  font-weight: 700;
  color: var(--grey-dark);
}

.heading_below-underline span {
  font-size: 25px;
  color: var(--pink);
}

.btn_fill {
  margin-top: 10px;
}

.full-width-text {
  margin-top: 30px;
}

@media screen and (max-width: 575px) {
  .derma-ads-banner-div {
    margin-top: 170px;
  }

  .ads-category-card {
    padding: 10px;
    margin: 0px 5px 20px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-first {
    order: -1;
  }

  .text-first {
    order: 1;
    text-align: start;
  }

  .award-ribbon-number {
    width: 80%;
  }

  .btn_fill {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 575px) and (max-width: 1000px) {
  .text-side {
    max-width: 100%;
  }
}
