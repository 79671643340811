.full-content.sidebar-open {
    padding-left: 250px;
    /* background: yellow; */
}

/* code for book upcoming bookings */
.book_upcoming_booking_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.book_upcoming_booking_popup-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    animation: fadeIn 0.3s ease-in-out;
}

.book_upcoming_booking_popup-content h5 {
    /* font-size: 24px; */
    font-weight: 700;
}

.book_upcoming_booking_popup-content .upcoming-booking-popup-buttons {
    display: flex;
    justify-content: center;
    gap: 30%;
    margin-top: -20px;
}

.upcoming-booking-popup-buttons {
    display: flex;
    justify-content: space-between;
}

.book_upcoming_booking_popup-content button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    background-color: var(--click-color);
    color: var(--white-color);
    margin-top: 20px;
}

/* Cancel Button */
.book_upcoming_booking_popup-content button:nth-child(1) {
    background-color: var(--pink);
    color: var(--white-color);
}

.book_upcoming_booking_popup-content button:nth-child(1):hover {
    transform: translateY(-2px);
}

/* Hover Effects for Buttons */
.book_upcoming_booking_popup-content button:hover {
    opacity: 0.8;
}

/* Fade-in Animation */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@media (max-width: 992px) {
    .full-content.sidebar-open {
        padding-left: 40px;
        /* background: yellow; */
    }
}

.full-content.no-sidebar {
    padding-left: 15px;
    /* background: green; */
}

/* pwa pop up div installtation code start */
.install-popup-div {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
    width: 100%;
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
    height: 0;
    transition: 1.5s ease-in-out;
}

.install-popup-div.open {
    opacity: 1;
    pointer-events: all;
    height: 100vh;
    transform: translateY(0%);
    animation: popupInTransition 2s ease-in-out;
}

@keyframes popupInTransition {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

.install-popup-div div {
    position: relative;
    width: 100%;
    max-width: 400px;
    background: #fff;
    border-radius: 12px;
    padding: 30px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.install-popup-div div .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: var(--click-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #fff;
    font-size: 1.1rem;
    cursor: pointer;
}

.install-popup-div div img {
    width: 80%;
    margin-bottom: 20px;
}

.install-popup-div div h1 {
    position: relative;
    margin-top: 20px;
    font-size: 0.7rem;
    color: #444;
    text-align: justify;
}

.install-popup-div div button {
    margin-top: 15px;
    background: var(--click-color);
    border: none;
    outline: none;
    padding: 5px 30px;
    border-radius: 8px;
    font-size: 0.8rem;
    color: #fff;
}

/* pop up div installation button end */