.ad_container {
    border-radius: 20px;
    overflow: hidden;
}

.ad_container img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
    display: none;
}

span.swiper-pagination-bullet {
    background: white;
    width: 12px;
    height: 12px;
}

@media only screen and (max-width: 575px) {
    .ad_container {
        border-radius: 0px;

    }
}