.ab_icon {
    background: var(--pink);
    transition: 0.3s;
    width: 50px;
    height: 50px;
    display: inline-block;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: fixed;
    right: 35px;
    bottom: 35px;
    z-index: 9999;
}

.ab_icon .material-symbols-outlined {
    color: white;
    font-size: 40px;
    font-weight: 500;
}

.ab_icon:hover {
    background: var(--click-color);
    transition: 0.3s;
}

.ab_icon:hover>.material-symbols-outlined {
    color: var(--white-color) !important;
    transition: 0.3s;

}

@media only screen and (max-width: 991px) {
    .ab_icon {
        bottom: 62px;
        width: 50px;
        height: 50px;
    }
}