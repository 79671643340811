.form_page h5.login_phone_title {
    text-align: center;
    margin-bottom: 10px;
    color: var(--black-color);
}

.diffmethodlogin .btn_border {
    margin-top: 14px;
}

.form_page {
    padding: 0px 0px;
    display: flex;
    align-items: center;
    margin: 50px 0px 50px 0px;
    height: 80vh;
}

.pglogo img {
    width: 172px;
    position: absolute;
    left: 25px;
    top: 7px;
}

.ot span,
.ot a {
    color: var(--click-color);
    text-decoration: none;
    margin-left: 5px;
    cursor: pointer;
}

.fp_inner {
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-parnt {
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    padding: 25px;
    background-color: var(--card-bg);
}

.phone_dentamax_logo {
    width: 250px;
    margin: 20px;
}

.submit_btn {
    text-align: center;
    margin: 15px 0px;
}

.ot {
    font-size: 14px;
}

.ot a {
    color: var(--click-color);
    font-size: 16px;
    font-family: 'p_bold';
}

.ot input.checkbox {
    margin-right: 6px;
    position: relative;
    top: 2px;
}

.two_ot {
    display: flex;
    justify-content: space-between;
}

.or {
    text-align: center;
    color: var(--primary-color);
    opacity: 0.5;
    position: relative;
}

.or:after {
    content: '';
    background: var(--primary-color);
    width: 70%;
    height: 2px;
    position: absolute;
    top: 11px;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0.5;
}

.or span {
    background: white;
    position: relative;
    z-index: 1;
    padding: 0px 10px;
}

.tab_btn_group {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: 22px 0px;
}

.tab_btn_group .btn_single {
    width: 50%;
}

.tab-buttons button {
    width: 40%;
    padding: 0px;
    min-height: 45px;
    border: none;
    border: 3px solid var(--primary-color);
    background: none;
    border-radius: 10px;
    color: var(--primary-color);
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.7px;
}

.phone_num_btn_login {
    font-size: 14px;
}

.email_btn_login {
    font-size: 14px;
}

.resend_otp_timer {
    font-size: 14px;
    color: var(--grey-dark);
    padding: 0px 0px 10px 0px;
}

.login_call_field_icon {
    position: absolute;
    top: 36px;
    left: 12px;
}

@media only screen and (max-width: 575px) {
    .main_formarea {
        /* margin: 50px 0px; */
        min-height: 50vh;
    }

    .pglogo img {
        width: 155px;
        left: 10px;
        top: 2px;
        z-index: 2;
    }

    .form-parnt {
        padding: 0px;
        margin: 0px;
        box-shadow: none;
        border-radius: 0px;
        padding-bottom: 25px;
    }

    .form-parnt h5.login_phone_title {
        margin-top: 15px;

    }

    .form_page {
        padding: 0px;
        margin: 150px 0px 50px 0px;
    }

    .tab_btn_group {
        /* display: block; */
    }

    .tab_btn_group .btn_single {
        width: 100%;
    }

    .tab_btn_group .btn_single:last-child {
        /* margin-top: 14px; */
    }

    .phone_num_btn_login {
        font-size: 12px;
    }

    .email_btn_login {
        font-size: 12px;
    }

}

@media only screen and (min-device-width : 575px) and (max-device-width : 991px) {
    .form_page {
        padding-top: 50px;
        min-height: inherit;
        margin: 0px 0px 50px 0px;
    }

    .main_formarea {
        margin: 30px 0px 60px 0px;
    }

    .phone_num_btn_login {
        font-size: 13px;
    }

    .email_btn_login {
        font-size: 13px;
    }

}