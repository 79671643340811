.international_cards_design_parent {
    padding: 50px 50px 0px 50px;
}

.international-two-img {
    border-radius: 30px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 97%;
    height: 375px;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.interntional_left_top_div {
    height: 500px;
    width: 95%;
    background-color: rgba(246, 246, 246, 0.77);
    margin-top: 10px;
    border-radius: 30px;
    margin-left: 30px;
}

.interntional_left_top_div div {
    /* width: 80%; */
    flex-direction: column;
    position: relative;
}


.interntional_left_top_div h1 {
    font-weight: 700;
    text-align: center;
}

.interntional_left_top_div p {
    text-align: center;
    margin: 30px;
}

.interntional_left_top_div div h1 {
    color: var(--pink);
    font-size: 30px;
    margin-top: -100px;
}

.international-left-top-bottom {
    margin: auto;
    text-align: center;
    margin-top: 10px;
}

.interntional_left_bottom_div {
    margin-top: 10px;
    /* border-radius: 30px; */
    margin-left: 17px;
    width: 100%;
}

.international-booking-dentist {
    max-width: 100%;
    height: 250px;
    border-radius: 30px;
    margin-bottom: 10px;
}


/* why choose us Section */
.international_why_choose_parent_div {
    margin: 143px 0px 0px 0px;
    background-color: var(--card-bg);
    padding: 50px 0px;
}

.fb_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ip_middle_circle {
    background-image: linear-gradient(135deg, #F0F0F0, #E3E3E3, #DFDFDF);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    margin-left: 200px;
    position: relative;
    box-shadow: 0px 3px 30px -7px rgba(0, 0, 0, 0.5);
    /* 3D effect */
}

.ip_middle_div_text {
    width: 55%;
    margin-left: 40px;
    text-align: center;
}

.ip_why_choose_circle {
    background-image: linear-gradient(135deg, #A38FE0, #8367CB, #6747C6);
    width: 200px;
    height: 200px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 999;
    top: 25%;
    left: -25%;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: white;
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.5);
}

.ip_whychoose_four_div {
    position: absolute;
}

.ip_last_circles-1 {
    background-image: linear-gradient(135deg, #e1e1e1, #adadad);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10%;
    top: 0%;
}

.ip_last_circles-2 {
    background-image: linear-gradient(135deg, #A48FDC, #6B4CC3);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -6%;
    top: 25%;
}

.ip_last_circles-3 {
    background-image: linear-gradient(135deg, #0FC7D9, #0EB4C6, #0D98A9);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -7%;
    top: 55%;
}

.ip_last_circles-4 {
    background-image: linear-gradient(135deg, #e878a7, #be53a4);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10%;
    top: 80%;
}

.ip_airplane_icon {
    width: 60px;
}

.ip_hand_icon {
    width: 70px;
}

.ip_time_icon {
    width: 90px;
}

.ip_securty_icon {
    width: 60px;
}

.ip_whychoose_details {
    top: -8%;
    position: absolute;
    margin-left: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.ip_whychoose_second_details {
    top: -10%;
    position: absolute;
    margin-left: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.ip_whychoose_third_details {
    top: 0%;
    position: absolute;
    margin-left: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.ip_whychoose_fourth_details {
    top: 25%;
    position: absolute;
    margin-left: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}


/* Our Journey Section */
.journeyBox {
    padding: 0px 150px 327px 150px;
}

.journeyBox .journey:first-child:before {
    border-left: 1px dashed var(--black-color);
    border-bottom: 1px dashed var(--black-color);
    border-radius: 0 0 0 50px;
    top: 0px;
    bottom: 100%;
    left: auto;
    right: 50%;
    height: 190px;
}

.journeyBox .journey:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 100%;
    margin-left: 0;
    width: 50px;
    height: 140px;
    z-index: 10;
}

.journeyBox .journey .block:last-child {
    border: 1px dashed var(--black-color);
    border-radius: 0 50px 50px 0;
    padding: 80px 50px;
    border-left: 0;
    margin-top: 0px;
    top: 189px;
}

.journeyBox .journey .block {
    position: relative;
    color: var(--black-color);
}

.journeyBox .journey .block .media {
    position: absolute;
    bottom: 100%;
    margin-bottom: -75px;
    left: 55px;
}

.media:first-child {
    margin-top: 0;
}

.journeyBox .journey .block .text .title3 {
    padding-bottom: 18px;
    font-weight: 700;
}

.journeyBox .journey {
    position: relative;
    clear: both;
    padding: 0px 50px;
}

/* second row */
.journeyBox .journey:nth-child(even) .block:last-child {
    border: 1px dashed var(--black-color);
    border-radius: 50px 0 0 50px;
    border-right: 0;
    border-left: 1px dashed var(--black-color);
}


.col-sm-pull-6 {
    right: 50%;
}

.journeyBox .journey.evenLast:before {
    border-right: 1px dashed var(--black-color);
    border-top: 1px dashed var(--black-color);
    border-radius: 0 50px 0 0;
    margin-top: 188px;
}

.ip_whychoose_phone_div_parent {
    display: none;
}

.ip_whychoose_upper_bg_color {
    background-color: var(--click-color);
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: white;
}

@media only screen and (max-width: 575px) {
    .international_cards_design_parent {
        padding: 60px 10px 0px 10px;
    }

    .interntional_left_top_div {
        height: 500px;
        width: 100%;
        background-color: rgba(246, 246, 246, 0.77);
        margin-top: 10px;
        border-radius: 30px;
        margin-left: 0px;
    }

    .interntional_left_top_div div {
        margin-top: -150px;
    }

    .interntional_left_bottom_div {
        display: none;
    }

    .international-two-img {
        display: none;
    }

    /* why choose us Section */
    .international_why_choose_parent_div {
        display: none;
    }

    .interntional_left_top_div div h1 {
        color: var(--black-color);
        font-size: 22px;
        margin-top: 0px;
    }

    /* Our Journey Section */
    .journeyBox .journey:first-child:before {
        display: none;
    }

    .journeyBox .journey .block:last-child {
        border: 1.5px inset var(--black-color);
        border-radius: 50px 0 0 50px;
        padding: 50px 30px 100px;
        border-left: 1.5px inset var(--black-color);
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
    }

    .journeyBox .journey .block .contact {
        position: static;
    }

    .journeyBox {
        padding: 160px 30px 40px;
    }

    .journeyBox .journey:nth-child(even) .block:last-child {
        border: 1.5px inset var(--black-color);
        border-radius: 50px 0 0 50px;
        padding: 50px 30px 100px;
        border-left: 1.5px inset var(--black-color);
        border-right: 0;
        border-top: 0;
        border-bottom: 0;
    }

    .journeyBox .journey {
        padding: 0px 0px;
    }

    .journeyBox .journey .block {
        position: static;
    }

    .journeyBox .journey.evenLast:before {
        display: none;
    }

    .ip_whychoose_phone_div_parent {
        display: block;
    }

}

@media (min-width: 575px) and (max-width: 1200px) {

    /* why choose us Section */
    .international_why_choose_parent_div {
        display: none;
    }

    /* our journey section */
    .journeyBox {
        padding: 0px 0px 327px 0px;
    }

    .ip_whychoose_phone_div_parent {
        display: block;
    }
}