.home_hero_banners_parent {
    margin-top: 50px;
    /* border: 1px solid #dcdcdc; */
}

.home_baneer_img {
    aspect-ratio: 3/1.20;
    /* height: 220px; */
    object-fit: cover;
    width: 100%;
    /* position: relative; */
}

.home-hero-ban-add-media-div {
    padding-top: 100px;
}

.active_inactive_home_banner {
    /* position: absolute; */
    /* bottom: 0px; */
    background-color: white;
    border: 1px solid #dcdcdc;
    width: 100%;
    padding: 2px 0px 6px 14px;
    margin-top: -5px;
}

.products-status-switch {
    cursor: pointer;
}

.swiper-button-prev,
.swiper-button-next {
    display: none;
}

span.swiper-pagination-bullet {
    background: var(--dark-blue);
    width: 12px;
    height: 12px;
}

.banner_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

h3.banner_subheading {
    font-size: 18px;
    font-size: 18px;
    margin-bottom: 8px;
    font-family: p_medium;
    text-transform: uppercase;
}

h2.banner_heading {
    margin-bottom: 8px;
    font-size: 51px;
    font-family: 'p_bold';
    text-transform: capitalize;
    max-width: 600px;
    background-image: linear-gradient(45deg, var(--dark-blue), var(--dark-blue), var(--pink), var(--gold));
    background-size: 200% 100%;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: animateText 10s linear infinite;
}

@keyframes animateText {
    0% {
        background-position: 200% 50%;
    }

    100% {
        background-position: -200% 50%;
    }
}

.bc_inner {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    .home_baneer_img {
        aspect-ratio: unset;
        height: 220px;
        object-fit: cover;
        width: 100%;
        margin-top: 40px;
    }

    .home-hero-ban-add-media-div {
        padding-top: 200px;
    }

    h2.banner_heading {
        font-size: 26px;
        max-width: 250px;
        width: 100%;
    }

    h3.banner_subheading {
        font-size: 12px;
        font-family: 'p_bold';
    }

    .banner_content {
        /* top: 55px; */
    }
}

@media (min-width: 575px) and (max-width: 991px) {
    .home_baneer_img {
        aspect-ratio: 3/1.5;
        /* height: 220px; */
        object-fit: cover;
        width: 100%;
    }
}

.s_home_services .offers-section-title {
    padding: 0px;
    border-radius: 0px;
    background: none;
    padding-top: 30px;
}