.youtube-media-style {
    width: 100%;
    height: 200px;
    border-radius: 15px;
}

.utube_video_parent {
    background-color: var(--card-bg);
}

.youtube-videos-row-div {
    padding-top: 30px;
}

/* Styles for screens smaller than 576px (phones) */
@media (max-width: 575) {
    .youtube-media-style {
        border-radius: 10px;
    }

    .youtube-videos-row-div {
        padding-top: 20px;
    }

    .active-inactive-text {
        font-size: 14px;
    }

    .utube_video_parent {
        padding: 25px 0px 0px 0px;
    }
}

/* Styles for screens between 576px and 767.98px (tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
    /* Your styles for medium screens go here */
}

/* Styles for screens between 768px and 991.98px (small desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
    /* Your styles for large screens go here */
}

/* Styles for screens larger than 992px (extra-large screens) */
@media (min-width: 992px) {
    /* Your styles for extra-large screens go here */
}