/* CALENDAR AND WEEK HOLIDAY SECTION */
.date-holiday-cards {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    width: 250px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.holiday-name {
    font-size: 14px;
    color: #666;
}

/* WEEK DAYS DESING */
/* Styles for the tab panel */
.tab-panel {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for date calender */
/* Styles for the tab panel */
.tab-panel {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styles for week buttons */
/* .week-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
} */

.week-button {
    padding: 10px 15px;
    border: 2px solid lightgray;
    border-radius: 4px;
    /* background-color: lightgray; */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.week-button.selected {
    background-color: var(--pink);
    color: white;
}

/* Styles for holiday cards */
.date-holiday-cards {
    /* margin: 10px 0; */
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.date-holiday-cards-content {
    display: flex;
    flex-direction: column;
}

/* .dashboard-date-holiday {
    font-weight: bold;
    margin-bottom: 5px;
} */

.holiday-name {
    color: #555;
}

/* Styles for Tab Panel One */
.tab-panel-one-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.date-calendar-container {
    margin-bottom: 20px;
}

.add-date-button {
    padding: 10px 20px;
    border: none;
    background-color: var(--pink);
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.week-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.week-button {
    padding: 10px 15px;
    /* border: 2px solid var(--pink); */
    border-radius: 4px;
    /* background-color: lightgray; */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.week-button.selected {
    background-color: var(--pink);
    color: white;
}

/* Styles for holiday cards */

.date-holiday-cards-content {
    display: flex;
    flex-direction: column;
}

.holiday-name {
    color: #555;
}

.week-holidays {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.week-name {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.week-name:hover {
    background-color: #e0e0e0;
}

.excel-icon-logo {
    margin-left: 15px;
}

@media only screen and (max-width: 767px) {
    .excel-icon-logo {
        margin-left: 0px;
    }
}