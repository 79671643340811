.settings-card {
    width: 100%;
    padding: 20px 10px;
    border-radius: 8px;
    border: 1px solid #ddd;
    background: #fff;
    cursor: pointer;
    margin: 10px 0;
}

.setting-form-card hr {
    border: none;
    border-bottom: 1px solid #eee;
}

.setting-form-card .setting-form-feild {
    position: relative;
    margin: 15px 0 10px 0;
    padding-top: 27px;
}

.setting-form-card .setting-form-feild input {
    width: 100%;
    background: #efefef;
    border: 1px solid #ddd;
    outline: none;
    height: 45px;
    padding: 0 10px;
    font-size: 1.1rem;
    border-bottom: 3px solid #4e5bf8;
}

.setting-form-card .setting-form-feild span {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.9rem;
    color: #000;
    font-weight: bold;
}

.settings-card .settings-card-details {
    display: flex;
    align-items: center;
    width: 55%;
}

.settings-card .settings-card-details div:nth-child(1) {
    text-align: center;
}

.settings-card .settings-card-details div:nth-child(1) span {
    position: relative;
    left: -5px;
    top: 3px;
    width: 40px;
    font-size: 1.8rem;
    color: #222;
}

.settings-card .settings-card-details div:nth-child(2) h1 {
    font-size: 1.2rem;
    color: #034687;
    font-weight: bold;
}

.settings-card .settings-card-details div:nth-child(2) h2 {
    font-size: 0.7rem;
    font-weight: normal;
    color: #888;
}

.settings-card .settings-card-arrow {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 45%;
}

.settings-card .settings-card-arrow span {
    padding-left: 10px;
    position: relative;
    top: 3px;
    color: #888;
    font-size: 2.0rem;
}

.toggle-switch {
    display: flex;
    align-items: center;
}

.toggle-switch div {
    position: relative;
    width: 50px;
    height: 23px;
    border-radius: 60px;
    transition: 0.5s;
    cursor: pointer;
}

.toggle-switch.off div {
    border: 1px solid white;
    background: var(--click-color);
}

.toggle-switch.on div {
    border: 1px solid #fff;
    background: var(--pink);
}

.toggle-switch div div {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: none;
    transition: 0.5s;
}

.toggle-switch.off div div {
    left: 6%;
    background: white;
}

.toggle-switch.on div div {
    background: #fff;
    left: calc(94% - 16px);
}

.toggle-switch small {
    font-size: 0.8rem;
    padding-right: 5px;
    font-weight: normal;
    color: #666;
}

.fixed-settings-breadcrum {
    position: -webkit-sticky;
    position: sticky;
    top: 65px;
    /* background: #f4f4f4; */
    padding: 15px 5px 10px 5px;
    z-index: 10;
    display: flex;
    align-items: center;
}

.fixed-settings-breadcrum .dots {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.fixed-settings-breadcrum span {
    font-size: 1.3rem;
    font-weight: bolder;
    color: #034687;
    padding: 0 4px;
    cursor: pointer;
}

.fixed-settings-breadcrum .dots-pop-up {
    position: absolute;
    top: 90%;
    background: #fff;
    width: auto;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5px;
}

.fixed-settings-breadcrum .dots-pop-up div {
    padding: 10px 20px;
    transition: 0.3s;
}

.fixed-settings-breadcrum .dots-pop-up div:nth-last-child(1) {
    border: none;
}

.fixed-settings-breadcrum .dots-pop-up div:hover {
    background: #efefef;
    border-radius: 8px;
}

.fixed-settings-breadcrum .dots-pop-up div h1 {
    font-size: 0.9rem;
    color: #444;
    font-weight: bold;
}