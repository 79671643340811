.project-filter {
    background: white;
}

.project-filter nav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 6px;
    border: 2px solid #c7c7c7;
}

.project-filter nav button {
    position: relative;
    width: 100%;
    height: 35px;
    transition: 0.5s;
    padding: 0px;
    border: 1px solid #dbdad9;
}

/* .project-filter button:nth-child(1) {
    background: var(--light-blue-bg);

}

.project-filter button:nth-child(2) {
    background: var(--light-gold);
}

.project-filter button:nth-child(3) {
    background: var(--rose);
}

.project-filter button:nth-child(4) {
    background: var(--grey-color);
} */

.project-filter button.active {
    /* background: #f05b8340 !important; */
    border-bottom: 2px solid var(--pink);
    font-weight: 700;
}


.project-filter nav button span {
    font-size: 14px;
    color: var(--same-black-color);
}

.project-filter nav button.active span {
    position: relative;
    top: 2px;
    color: var(--pink);
}

.project-filter nav button small {
    position: absolute;
    top: -11px;
    right: 10%;
    width: 20px;
    height: 20px;
    padding: 5px;
    font-size: 15px;
    background: var(--same-dark-blue);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
}

@media only screen and (max-width: 767px) {
    .project-filter {
        /* display: none;    */
    }

    .project-filter nav button span {
        font-size: 12px;
    }

    .project-filter nav button {
        height: 35px;
    }
}