section.doctors-area {
    padding: 100px 0px;
}

.doctor-item {
    /* margin-bottom: 30px; */
    background-color: var(--card-bg);
    text-align: center;
    box-shadow: 0px 0px 20px 0px #ddd;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.doctor-item .doctor-top {
    position: relative;
    border-radius: 10px;
}

.pghome-doctor-row {
    display: flex;
    flex-wrap: wrap;
}

.pghome-doctor-column {
    display: flex;
    margin-bottom: 40px;
}

.pghome-doctor-top {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.pghome-doctor-top {
    position: relative;
    flex: 1;
}

.pghome-doctor-bottom {
    flex: 1;
}

.doctor-item .doctor-top img {
    width: 100%;
    border-radius: 10px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
}

.doctor-top-team9 img {
    width: 100%;
    border-radius: 10px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
}

.doctor-top-team9 a {
    display: inline-block;
    color: var(--dark-blue);
    font-size: 15px;
    font-weight: 600;
    padding: 15px 0;
    position: absolute;
    border-radius: 5px;
    left: 0;
    right: 0;
    bottom: 10px;
    opacity: 0;
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    transition: 0.5s all ease;
}

.doctor-item .doctor-top a {
    display: inline-block;
    color: var(--click-color);
    font-size: 15px;
    font-weight: 600;
    padding: 15px 0;
    position: absolute;
    border-radius: 5px;
    left: 0;
    right: 0;
    bottom: 10px;
    opacity: 0;
    max-width: 160px;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
    transition: 0.5s all ease;
}

.doctor-item .doctor-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
}

.doctor-item .doctor-bottom h3 a {
    display: block;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
    color: #232323;
    pointer-events: none;
}

.doctor-item .doctor-bottom span {
    display: block;
    color: var(--black-color);
    font-size: 15px;
}

.doctor-bottom p {
    padding: 0 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-transform: capitalize;
}

.doctor-item .doctor-top a {
    opacity: 1;
    bottom: 20px;
    transition: 0.5s all ease;
}

.doctor-btn {
    text-align: center;
}

.doctor-btn a {
    margin-top: 30px;
    display: inline-block;
    font-weight: 600;
    color: #232323;
    font-size: 18px;
    border: 2px solid #386ae6;
    padding: 18px 55px;
    border-radius: 10px;
}