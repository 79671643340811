.faq_section_single .section_title {
    text-align: center;
}

.faq_section_single .section_title h3 {
    padding-bottom: 20px !important;
    margin-bottom: 10px !important;
    display: inline-block;
    border-bottom: 1px solid #e9573354;
    color: var(--pink);
    font-weight: 700;
    letter-spacing: 1px;
}

.faq .faq_section_single {
    margin-top: 100px;
}

.faq .faq_section_single:last-child {
    margin-bottom: 0px;
    box-shadow: 0px 0px 20px 0px;
    border-radius: 30px;
    padding: 50px;
    background-color: var(--card-bg);
}

.sect_padding {
    margin-bottom: 100px;
}


.accordion .accordion-button:not(.collapsed) {
    color: var(--theme-green);
    background: none;
    box-shadow: none;
}

.accordion button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
}

.accordion-item {
    border: none;
}

button.accordion-button {
    border-bottom: 1px solid #e9573354;
}

.accordion-item .accordion-button:focus {
    border-color: #e9573354;
}

.accordion .accordion-button:not(.collapsed) {
    border-bottom: 1px solid var(--click-color);
    color: var(--click-color);
}

button.accordion-button {
    border-bottom: 1px solid #e9573354;
    font-weight: 700;
    color: #333333;
    font-size: 20px;
}


@media (max-width: 767px) {
    .faq_section_single .section_title h3 {
        padding-bottom: 12px !important;
        margin-bottom: 5px !important;
    }

    button.accordion-button {
        padding: 15px;
        font-size: 16px;
    }

    .accordion-item .accordion-body {
        padding: 15px;
        font-size: 14px;
    }

    .faq .faq_section_single {
        margin-bottom: 50px;
    }

    .faq .faq_section_single:last-child {
        padding: 25px;
        margin-top: 50px;
    }

    .sect_padding {
        margin-bottom: 80px;
    }

}