.insurance-container {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.insurance-image-wrapper {
    /* margin-bottom: 20px; */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    justify-content: center;
}

.insurance-image-wrapper:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.insurance-image {
    width: 100%;
    /* Make image take full width of its container */
    max-width: 200px;
    height: 100px;
    /* Optional: limit the maximum width */
    border-radius: 10px;
}