.image_slider_parents_div {
    background-color: var(--card-bg);
    padding: 50px 0px 50px 0px;
}

.beforeaftrer-left-div {
    color: var(--black-color);
}

.beforeaftrer-left-div h4 {
    margin: 30px 0px 30px 0px;
}

.beforeaftrer-left-div h1 {
    margin: 30px 0px 30px 0px;
    font-weight: 700;
    letter-spacing: 1px;
}

.beforeaftrer-left-div p {
    margin: 30px 0px 30px 0px;
}

.beforeafter_dta_right {
    display: none;
}

/* 
.smile-gallery-paret {
    position: relative;
}

.smile_before {
    position: absolute;
    top: -10;
    right: 50;
}

.smile_after {
    position: absolute;
} */


@media only screen and (max-width:775px) {
    .beforeaftrer-left-div p {
        display: none;
    }

    .beforeaftrer-left-div button {
        display: none;
    }

    .beforeaftrer-left-div h1 {
        text-align: center;
    }

    .beforeaftrer-left-div h4 {
        text-align: center;
    }

    .beforeafter_dta_right {
        display: block;
    }

    .beforeafter_dta_right p {
        margin: 30px 0px;
    }
}