body,
html {
  height: 100%;
}

.thank-you-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #4a90e2, #9013fe);
  color: white;
  text-align: center;
}

.thank-you-content {
  animation: fadeIn 2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-you-content h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.thank-you-content p {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.thank-you-content img {
  width: 300px;
  height: auto;
  margin: 1em 0;
}

.thank-you-content button {
  padding: 0.5em 1em;
  font-size: 1em;
  color: #4a90e2;
  background: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
}

.thank-you-content button:hover {
  background: #4a90e2;
  color: white;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
