.booking-services-parent-div {
    padding-top: 70px;
    min-height: 80vh;
}

.more_services_carousel_img {
    aspect-ratio: 1/1;
    /* height: 350px; */
    border: 3px solid var(--click-color);
    cursor: pointer;
    border-radius: 10px;
}

.status-button-container {
    position: absolute;
    top: 7px;
    /* Adjust as needed */
    left: 20%;
    /* Position in the middle */
    transform: translateX(-50%);
    z-index: 1;
}

.products-cards-parent-div {
    margin: 10px 0px;
}

.more_services_img_heading {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 13px;
    /* font-weight: 600; */
    background-color: var(--pink);
    color: white;
    padding: 5px 15px 5px 15px;
    border-radius: 35px;
}

.products_heding_contner h4 {
    color: var(--pink);
    font-weight: 700;
}

.booking_services_price_location {
    background-color: var(--grey-light);
    /* display: flex; */
    padding: 5px 10px;
    color: var(--black-color);
}

.booking_services_name_haedings_parent {
    padding: 5px;

}

.booking_services_name_haedings_parent h3 {
    padding: 8px 0px;
}

.booking_services_name_haedings {
    font-size: 22px;
    font-weight: 700;
    color: black;
}

.booking_services_name_haedings_parent p {
    font-size: 13px;
    color: var(--text-light-grey);
}

.booking_services_two_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    padding: 10px;
    color: var(--click-color);
}

.booking_services_two_buttons div p {
    color: var(--click-color);
}

.products_activ_inctive_btn {
    background: var(--click-color);
    border: none;
    border-radius: 6px;
    color: var(--white-color);
    padding: 5px 20px;
    font-family: 'p_bold';
    letter-spacing: 1px;
    text-transform: capitalize;
    font-size: 1rem;
    z-index: 999;
}


.booking_services_see_all_btn {
    font-size: 15px;
    background-color: transparent;
    color: var(--click-color);
    font-weight: 700;
    align-items: center;
    border-radius: none;
    border: none;
}

.booking-services-section-title-2 {
    margin: 100px 0px 30px 0px;
}

.booking-services-section-title-2 h2 {
    font-weight: 700;
    font-size: 38px;
    margin-bottom: 0;
    padding-bottom: 15px;
    position: relative;
    color: var(--click-color);
}

.product-thumb-img {
    position: relative;
}

.product-thumb-img div {
    /* position: absolute; */
    /* top: 0px;
    left: 0px; */
    font-weight: 700;
    color: var(--click-color);
    /* background-color: aliceblue; */
    /* padding: 8px 10px; */
    border-radius: 10px 10px 0px 0px;
}

#top_heading {
    font-size: 40px;
    font-weight: 700;
    color: var(--click-color);
}

.services_heading h1:after {
    position: absolute;
    content: "";
    display: flex;
    width: 80px;
    height: 5px;
    background-color: var(--dark-blue);
    border-radius: 5px;
    margin: 10px 0px 10px 0px;
}

/* Add this CSS in your component's CSS file or a global CSS file */
.doctors-name-h5 {
    font-size: 17px;
    color: var(--text-grey);
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* Adjust this value to set the number of visible lines */
    -webkit-box-orient: vertical;
}

.product-short-detail {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limit to two lines */
    -webkit-box-orient: vertical;
    min-height: 3em;
    /* Ensure at least two lines of text */
}


.doctor-item {
    background-color: var(--white-color);
    text-align: center;
    box-shadow: 0px 0px 20px 0px var(--card-bg);
    border-radius: 10px;
}

.doctor-item .doctor-top {
    position: relative;
    border-radius: 10px;
}

.doctor-item .doctor-top img {
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;
}

@media (max-width: 575px) {
    .more_services_img_heading {
        font-size: 11px;
    }

    .booking-services-parent-div {
        padding-top: 55px;
    }

    #top_heading {
        font-size: 20px;
    }

    .booking_services_price_location div h5 {
        font-size: 12px;
    }

    .booking_services_name_haedings {
        font-size: 18px;
    }

    .booking_services_name_haedings_parent h3 {
        padding: 5px;
    }

    .booking_services_name_haedings_parent p {
        font-size: 13px;
    }

    .booking_services_two_buttons {
        padding: 5px;
        margin-top: 5px;
    }

    .booking_services_see_all_btn {
        font-size: 11px;
        display: flex;
        align-items: center;
        border-radius: none;
        border: none;
    }

    .product-thumb-img div {
        /* top: 3px; */
        /* left: 3px; */
        /* padding: 2px 4px; */
        /* border-radius: 5px; */
    }

    .product-thumb-img div h5 {
        font-size: 13px;
        font-weight: 700;
    }

    .products_activ_inctive_btn {
        padding: 5px 5px;
        font-size: 12px;
    }

    .booking-services-section-title-2 {
        margin: 50px 0px 5px 0px;
    }

    .booking-services-section-title-2 h2 {
        font-size: 25px;
    }

    .doctors-name-h5 {
        font-size: 15px;
    }

}