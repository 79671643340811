.testimonial_single {
    padding: 50px;
    height: 400px;
    border: 6px solid white;
    border-radius: 40px;
    margin: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.testimonials_heading {
    padding: 20px 0px 20px 20px;
}

.testimonial_sect {
    background: var(--card-bg);
}

.quote img {
    width: 80px;
    height: auto;
    color: var(--black-color);
}

.quote {
    position: absolute;
    background: var(--card-bg);
}

.quote_down.quote {
    padding-left: 12px;
    top: -35px;
    left: -30px;
    transform: rotate(180deg);
}

.quote_up.quote {
    bottom: -35px;
    right: -30px;
    padding-left: 12px;
}

.client_info_div .client_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.client_info_div .client_img {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    overflow: hidden;
}

.client_info_div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    margin-top: 20px;
}

audio.c_interview {
    margin: 25px 0px;
}

.testimonial_sect .section_title h3 {
    color: white;
}

.carousel_container {
    margin-top: 40px;
    padding-bottom: 35px;
}

.testimonial_sect .section_title_effect {
    -webkit-text-stroke-color: #ffffff70;
}

.testimonial_single h5 {
    color: var(--black-color);
    font-weight: 400;
    font-size: 18px;
    opacity: 0.8;
}

.client_info {
    color: var(--black-color);
}

.client_info h6 {
    opacity: 0.8;
}


@media (max-width: 767px) {
    .testimonial_single {
        padding: 20px;
        border: 4px solid white;
        margin: 10px;
        height: 350px;
    }

    .quote img {
        width: 40px;
        height: auto;
    }

    .quote_down.quote {
        top: -15px;
        left: -12px;
    }

    .quote_up.quote {
        bottom: -15px;
        right: -12px;
    }

    .testimonial_single h5 {
        font-size: 14px;
        padding: 10px;
    }

    .client_info_div {
        margin-top: 10px;
    }

    .client_info_div .client_img {
        width: 55px;
        height: 55px;
    }

    .client_info h4 {
        font-size: 16px;
    }

    .client_info h6 {
        font-size: 14px;
    }

    .testimonials_heading {
        margin-left: -10px;
    }
}