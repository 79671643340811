.pg_contact_us .banner img {
    /* margin-top: 100px; */
    aspect-ratio: unset;
    object-fit: cover;
    height: 500px;
    width: 100%;
    /* margin-top: 100px;*/
}

.loc_em_ph_inner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 40px;
    box-shadow: 0px 0px 10px 0px #ddd;
    margin: 40px 0px;
    display: flex;
    justify-content: space-evenly;
    gap: 15px;
    border-radius: 20px;
}

.lep_single .icon_div {
    background: var(--pink);
    display: inline-block;
    border-radius: 50%;
    padding: 20px;
    height: 80px;
    width: 80px;
}

.lep_single_address {
    text-transform: capitalize;
    letter-spacing: 1px;
}

.lep_single .icon_div img {
    width: 35px;
}

.icon_div span {
    color: #ffffff;
    padding: 3px;
    font-size: 35px;
}

.lep_single h4 {
    font-size: 26px;
    font-family: 'p_bold';
    margin: 10px 0px;
}

section.form_sec {
    margin: 80px 0px;
    display: flex;
    flex-wrap: wrap;
}

.success {
    color: #ffffff;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
}

.pg_contact_us h2.banner_heading {
    color: var(--pink);
}

section.form_sec .contact_us_left_img {
    width: 40%;
    display: flex;
}

section.form_sec .contact_us_left_img img {
    width: 80%;
    /* height: 100%; */
    object-fit: cover;
    margin: auto;
}

.lep_single {
    text-align: center;
    width: 33%;
    color: var(--black-color);
}

.lep_single h6 {
    font-size: 16px;
    letter-spacing: 1px;
}

section.form_sec .right_form {
    width: 60%;
    padding: 80px;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
}

section.form_sec .right_form::after {
    content: '';
    background: var(--black-color);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0.5;
    z-index: -1;
}

.map_heading {
    margin: 60px auto 30px auto;
    font-weight: 600;
    font-size: 23px;
    position: relative;
    color: var(--click-color);
}

.map_heading::after {
    content: "";
    position: absolute;
    border-radius: 10px;
    bottom: -10px;
    /* Adjust this value to control the distance of the line from the heading */
    left: 50%;
    /* Position the line in the center */
    transform: translateX(-50%);
    /* Center the line horizontally */
    width: 85px;
    /* Adjust the width of the line */
    height: 5px;
    /* Adjust the height of the line */
    background-color: var(--dark-blue);
    /* Adjust the line color */

}





@media only screen and (max-width: 767px) {
    section.loc_em_ph .container {
        max-width: 100%;
    }

    .pg_contact_us .banner img {
        aspect-ratio: unset;
        height: 255px;
        object-fit: cover;
        height: 220px;
        /* margin-top: 100px; */
    }

    .loc_em_ph_inner {
        display: grid;
        justify-content: inherit;
        padding: 15px;
        align-items: left;
    }

    .lep_single {
        width: 100%;
    }

    .lep_single .icon_div {
        padding: 0px;
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .lep_single h4 {
        font-size: 20px;
        margin: 5px 0px;
    }

    .lep_single .icon_div img {
        width: 20px;
    }

    .icon_div span {
        font-size: 27px;
    }

    .lep_single_address {
        /* width: 250px; */
    }

    section.form_sec .contact_us_left_img,
    section.form_sec .right_form {
        width: 100%;
    }

    section.form_sec .right_form {
        padding: 25px 15px;
    }

    section.form_sec {
        margin: 40px 0px;
    }

    .map_heading {
        font-size: 20px;
    }
}